export default function nl2br(str) {
    if (typeof str !== 'string') {
        return str;
    }

    if (str.search(/\\n/g) === -1) {
        return str;
    }

    return str.split('\\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
    })
};
