import React, { useEffect, useState } from 'react';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';
import { Dialog, Container, Grid } from '@material-ui/core';
import PWAPrompt from 'react-ios-pwa-prompt';
import useTranslate from '../hooks/useTranslate';

export default function Splash(props) {
    const t = useTranslate('component');

    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, window.location.hostname === 'localhost' ? 500 : 4000);
    }, [])

    // pwaPromtTitle: Add to Home Screen
    // pwaPromtBody: This website has app functionality. Add it to your home screen to use it in fullscreen.
    // pwaPromtShareButton: 1) Press the 'Share' button
    // pwaPromtAddHomeButton: 2) Press 'Add to Home Screen'
    // pwaPromtClose: Cancel

    return (
        <>
        {!show && <PWAPrompt promptOnVisit={1} timesToShow={3} copyBody={t('pwaPromtBody')} copyTitle={t('pwaPromtTitle')} copyShareButtonLabel={t('pwaPromtShareButton')} copyAddHomeButtonLabel={t('pwaPromtAddHomeButton')} copyClosePrompt={t('pwaPromtClose')} permanentlyHideOnDismiss={false}/> }
        <Dialog fullScreen transitionDuration={0} open={show} style={{backgroundColor: '#fafafa'}}>
            <Container maxWidth="xs">
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                    <img alt="App logo" src="/logo512.png" style={{ maxWidth: '80%', height: 'auto' }} />
                    <h1>{t('splashHeader')}</h1>
                    <p style={{ fontSize: '1.5em', textAlign: 'center', maxWidth: '50%' }}>{t('splashSubheader')}</p>
                    <p>&copy; {(new Date()).getFullYear()}, {t('splashFooter')}</p>
                </Grid>
            </Container>
        </Dialog>
        </>
    )
}
