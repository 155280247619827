import { useRecoilValue } from "recoil";
import { appMessagesState } from "../data/app";

function useTranslate(namespace) {
    const messages = useRecoilValue(appMessagesState)

    return key => {
        const name = namespace + "." + key;
        if (!messages.hasOwnProperty(namespace) || !messages[namespace].hasOwnProperty(key)) {
            return name;
        }

        return messages[namespace][key];
    }
}

export default useTranslate
