import React from 'react';

import { Button, Card, Container, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AttackLayout from '../../layouts/private/AttackLayout'

// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../../hooks/useTranslate';
import { useRecoilValue } from 'recoil';
import { attackState } from '../../data/attack';
import { useLocation, useNavigate, useParams } from 'react-router';
import TreatmentCardContent from '../../components/TreatmentCardContent';
import useSafeArea from '../../hooks/useSafeArea';

function AttackTreatmentsIndex() {

    const t = useTranslate('attack')
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const { calcSab } = useSafeArea()

    let { attack_id } = useParams();
    const attack = useRecoilValue(attackState(attack_id))

    if (attack === undefined) {
        return null;
    }

    return (
        <AttackLayout>
            {attack.treatments.length > 0 ? attack.treatments.map((treatment, index) => (
                <Card key={index} onClick={() => navigate(`/attacks/${attack.id}/treatments/${treatment.id}`)} style={{ margin: 20, borderRadius: 10 }} raised>
                    <TreatmentCardContent treatment={treatment} />
                </Card>
            )) : ''}
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button onClick={() => navigate(`${location.pathname}/create`)} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('viewAddTreatment')}</span></Button>
            </Container>
        </AttackLayout>
    )
}

export default AttackTreatmentsIndex;
