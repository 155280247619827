import React, { useState } from 'react';
import useTranslate from '../../hooks/useTranslate';

import { List, ListItem, TextField } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, ListItemText, Grid } from '@material-ui/core';
import moment from 'moment';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';

import ListItemCheckboxDialog from '../../components/ListItemCheckboxDialog'


import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../data/user';
import MoreLayout from "../../layouts/private/MoreLayout";
import { appLanguageState, appOptionsState, useRefreshAppData } from '../../data/app';
import StepForm from '../../components/StepForm';
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router';


function UserAccount(props) {
    const t = useTranslate('user');
    const options = useRecoilValue(appOptionsState)
    // const { options } = useSelector(state => state.app);
    const user = useRecoilValue(userState)
    const navigate = useNavigate()

    const [values, setValues] = useState(user)
    const [updateEmail, setUpdateEmail] = useState('')
    const setAppLanguage = useSetRecoilState(appLanguageState)
    const initEmail = user.email

    const refreshAppData = useRefreshAppData()
    const api = useApi()

    const [birthdate, setBirthdate] = useState(user.birthdate ? moment.unix(user.birthdate) : null)


    // ...
    const handleSubmit = () => {
        api.userUpdate({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone_country: values.phone_country,
            phone: values.phone,
            city: values.city,
            state: values.state,
            country: values.country,
            gender: values.gender,
            birthdate: birthdate ? birthdate.startOf('day').unix() : null,

            language: values.language,
            direction: values.direction,
            language_datetime: values.language_datetime,
            start_screen: values.start_screen,
            timezone: values.timezone,

            preferred_therapies: values.preferred_therapies ?? [],
            clinical_trials: values.clinical_trials,
            patient_id_consent: values.patient_id_consent ? (values.patient_id_consent !== 1 ? values.patient_id_consent : 1) : 0,
            patient_id: values.patient_id_consent ? (values.patient_id ?? '') : '',
        }).then(() => {
            setAppLanguage(values.language);
            localStorage.setItem('language', values.language)
            refreshAppData()
            navigate(`/more`)
        })
    }

    const handleSetValues = (name, value) => {
        const _values = { ...values }
        _values[name] = value;
        setValues(_values);
    }

    const handleSetPreferredTherapies = (value) => {
        const _keys = Object.keys(value)

        const _selected = []
        for (let index = 0; index < _keys.length; index++) {
            const _key = _keys[index];
            if (value[_key] === true) {
                _selected.push(_key)
            }
        }

        handleSetValues('preferred_therapies', _selected)
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const getTreatmentTherapyOptions = () => {
        const _options = {}
        const _keys = Object.keys(options.treatment.therapies);
        for (let index = 0; index < _keys.length; index++) {
            const therapy_id = _keys[index];
            const treatment = options.treatment.therapies[therapy_id];

            if (treatment.clinical_trials === 1 && user.clinical_trials !== 1) {
                continue;
            }

            if (treatment.acute) {
                _options[therapy_id] = treatment.name
            }
        }

        return _options;
    }

    const getTreatmentTherapyValues = () => {
        let preferred_therapies = {}
        if (values.preferred_therapies && values.preferred_therapies.length > 0) {
            for (let index = 0; index < values.preferred_therapies.length; index++) {
                const element = values.preferred_therapies[index];
                preferred_therapies[element] = true;
            }
        }

        return preferred_therapies;
    }

    const dateFormat = (locale) => {
        switch (locale) {
            case 'da':
            case 'de':
            case 'de-at':
            case 'de-ch':
            case 'fr-ch':
            case 'mk':
            case 'no':
            case 'nn':
            case 'pl':
            case 'fi': // return 'DD.MM';
            case 'fr':
            case 'pt':
            case 'pt-br':
                return 'DD/MM';
            // case 'fr-ca':
            // case 'sv':
            // return 'MM-DD';
            default:
                return 'MM/DD';
        }
    }

    return (
        <MoreLayout>
            <StepForm onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <FormControl required fullWidth>
                            <TextField name="first_name" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.first_name} label={t('updateFirstNameLabel')} InputLabelProps={{ shrink: true }} required />
                            <FormHelperText>{t('updateFirstNameHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl required fullWidth>
                            <TextField name="last_name" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.last_name} label={t('updateLastNameLabel')} InputLabelProps={{ shrink: true }} required />
                            <FormHelperText>{t('updateLastNameHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <FormControl required fullWidth>
                            <TextField name="email" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.email} label={t('updateEmailLabel')} InputLabelProps={{ shrink: true }} required />
                            <FormHelperText>{t('updateEmailHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>

                    {initEmail !== values.email &&
                        <ListItem>
                            <FormControl required fullWidth>
                                <TextField name="email_again" onChange={e => setUpdateEmail(e.target.value)} defaultValue={updateEmail} label={t('updateEmailAgainLabel')} InputLabelProps={{ shrink: true }} required />
                                <FormHelperText>{t('updateEmailAgainHint')}</FormHelperText>
                            </FormControl>
                        </ListItem>
                    }

                    <ListItem>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel shrink>{t('updatePhoneCountryLabel')}</InputLabel>
                                    <Select value={values.phone_country ? values.phone_country : ''} name="phone_country" onChange={e => handleSetValues(e.target.name, e.target.value)}>
                                        <MenuItem value=''></MenuItem>
                                        {Object.entries(options.user.phone_countries).map(item => {
                                            return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText>{t('updatePhoneCountryHint')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl required fullWidth>
                                    <TextField type="number" name="phone" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.phone} label={t('updatePhoneLabel')} InputLabelProps={{ shrink: true }} required />
                                    <FormHelperText>{t('updatePhoneHint')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ListItem>

                    <ListItem>
                        <FormControl fullWidth>
                            <TextField name="city" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.city} label={t('updateCityLabel')} InputLabelProps={{ shrink: true }} />
                            <FormHelperText>{t('updateCityHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <TextField name="state" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.state} label={t('updateStateLabel')} InputLabelProps={{ shrink: true }} />
                            <FormHelperText>{t('updateStateHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel shrink>{t('updateCountryLabel')}</InputLabel>
                            <Select value={values.country ? values.country : ''} name="country" onChange={e => handleSetValues(e.target.name, e.target.value)}>
                                <MenuItem value=''></MenuItem>
                                {Object.entries(options.user.countries).map(item => {
                                    return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateCountryHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <MuiPickersUtilsProvider utils={DateMomentUtils} locale={user.language_datetime}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            views={['month', 'date']}
                                            id="date-picker-dialog"
                                            label={t('updateBirthDateLabel')}
                                            value={birthdate}
                                            onChange={setBirthdate}
                                            format={dateFormat(user.language_datetime)}
                                            InputLabelProps={{ shrink: true }}
                                            cancelLabel={t('updateBirthDateCancel')}
                                            okLabel={t('updateBirthDateOK')}
                                        />
                                        <FormHelperText>{t('updateBirthYearHint')}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            views={['year']}
                                            id="year-picker-dialog"
                                            label={t('updateBirthYearLabel')}
                                            format="yyyy"
                                            value={birthdate}
                                            onChange={setBirthdate}
                                            InputLabelProps={{ shrink: true }}
                                            cancelLabel={t('updateBirthDateCancel')}
                                            okLabel={t('updateBirthDateOK')}
                                        />
                                        <FormHelperText>{t('updateBirthDateHint')}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel shrink>{t('updateGenderLabel')}</InputLabel>
                            <Select value={values.gender} name="gender" onChange={e => handleSetValues(e.target.name, e.target.value)}>
                                {Object.entries(options.user.genders).map(item => {
                                    return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateGenderHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateStartScreenLabel')}</InputLabel>
                            <Select value={values.start_screen} name="start_screen" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                {Object.entries(options.user.start_screens).map(item => {
                                    return <MenuItem key={item[0]} value={item[0]}>{t('optionStartScreen' + capitalize(item[0]))}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateStartScreenHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateLanguageLabel')}</InputLabel>
                            <Select value={values.language} name="language" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                {Object.entries(options.user.languages).map(item => {
                                    return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateLanguageHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateDirectionLabel')}</InputLabel>
                            <Select value={values.direction} name="direction" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                <MenuItem value="ltr">{t('optionDirectionLTR')}</MenuItem>
                                <MenuItem value="rtl">{t('optionDirectionRTL')}</MenuItem>
                            </Select>
                            <FormHelperText>{t('updateDirectionHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateLanguageDateTimeLabel')}</InputLabel>
                            <Select value={values.language_datetime} name="language_datetime" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                {Object.entries(options.user.language_datetimes).map(item => {
                                    return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateLanguageDateTimeHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateTimezoneLabel')}</InputLabel>
                            <Select value={values.timezone} name="timezone" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                {Object.entries(options.user.timezones).map(item => {
                                    return <MenuItem key={item[0]} value={item[1]}>{item[1]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('updateTimezoneHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                </List>
                <List>
                    <ListItemCheckboxDialog title={t('updatePreferredTherapies')} emptyText={t('emptyPreferredTherapies')} onChange={handleSetPreferredTherapies} options={getTreatmentTherapyOptions()} value={getTreatmentTherapyValues()} />
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('updateClinicalTrialsLabel')}</InputLabel>
                            <Select value={values.clinical_trials} name="clinical_trials" onChange={e => handleSetValues(e.target.name, e.target.value)} required>
                                {/* {Object.entries(options.user.timezones).map(item => {
                                    return <MenuItem key={item[0]} value={item[1]}>{item[1]}</MenuItem>
                                })} */}
                                <MenuItem value={0}>{t('clinical_trials0')}</MenuItem>
                                <MenuItem value={1}>{t('clinical_trials1')}</MenuItem>
                            </Select>
                            <FormHelperText>{t('updateClinicalTrialsHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                    {values.country === 'us' &&
                        <>
                            <ListItem>
                                <ListItemText primary={t('updatePatientIdConsentHeader')} secondary={t('updatePatientIdConsentText')} />
                            </ListItem>
                            <ListItem>
                                <FormControl fullWidth>
                                    <InputLabel>{t('updatePatientIdConsentLabel')}</InputLabel>
                                    <Select value={values.patient_id_consent ? 1 : 0} name="patient_id_consent" onChange={e => handleSetValues(e.target.name, e.target.value)}>
                                        <MenuItem value={0}>{t('patient_id_consent0')}</MenuItem>
                                        <MenuItem value={1}>{t('patient_id_consent1')}</MenuItem>
                                    </Select>
                                    {(values.patient_id_consent > 0 && values.patient_id_consent !== 1) &&
                                        <FormHelperText>{t('updatePatientIdConsentHint').formatUnicorn({ date: moment.unix(values.patient_id_consent).format('ll') })}</FormHelperText>
                                    }
                                </FormControl>
                            </ListItem>
                            {values.patient_id_consent > 0 &&
                                <ListItem>
                                    <FormControl fullWidth>
                                        <TextField name="patient_id" onChange={e => handleSetValues(e.target.name, e.target.value)} defaultValue={values.patient_id} label={t('updatePatientIdLabel')} InputLabelProps={{ shrink: true }} />
                                        <FormHelperText>{t('updatePatientIdHint')}</FormHelperText>
                                    </FormControl>
                                </ListItem>
                            }
                        </>
                    }
                </List>
            </StepForm>
        </MoreLayout>
    )
}

export default UserAccount;
