import React from 'react';
import { Outlet, useParams } from 'react-router';

import AttackIndex from '../../routes/private/AttackIndex';
import { Dialog } from '@material-ui/core';
import useConsole from '../../hooks/useConsole';

function AttackIndexLayout() {
    const console = useConsole()
    console.debug('route: AttackIndexLayout');

    let { attack_id } = useParams();

    return (
        <>
            <AttackIndex />

            <Dialog fullScreen open={attack_id !== undefined}>
                <Outlet />
            </Dialog>
        </>
    );
}

export default AttackIndexLayout;
