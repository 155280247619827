import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Container, IconButton, Typography, useTheme, Grid } from '@material-ui/core';
import moment from 'moment';
// import prophylacticIcon from '../../icons/attacks.svg';
import { ReactComponent as ProphylacticIcon } from '../../icons/prophylactic.svg';
// import { ReactComponent as AttackProgressIcon } from '../icons/attack-progress.svg';
import { ReactComponent as EditPenIcon } from '../../icons/edit-pen.svg';
import HealingIcon from '@material-ui/icons/Healing'

import ProphylacticLayout from '../../layouts/private/ProphylacticLayout'

// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../../hooks/useTranslate';
import { useRecoilValue } from 'recoil';
import { prophylacticState } from '../../data/prophylactic';
import { useLocation, useNavigate, useParams } from 'react-router';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import { appOptionsState } from '../../data/app';
import useApi from '../../hooks/useApi';
import ConfirmDialog from '../../components/ConfirmDialog';
import useSafeArea from '../../hooks/useSafeArea';


function ProphylacticView() {
    const t = useTranslate('prophylactic');
    const location = useLocation()
    const theme = useTheme()
    const navigate = useNavigate()
    let { prophylactic_id } = useParams();
    const prophylactic = useRecoilValue(prophylacticState(prophylactic_id))
    const [confirmDelete, setConfirmDelete] = useState(false)
    const options = useRecoilValue(appOptionsState)
    const api = useApi()
    const { calcSab } = useSafeArea()

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // ...
    const handleOnDelete = async () => {
        await api.prophylacticDelete(prophylactic).then(() => {
            navigate(`/prophylactics`)
        })
    }

    // ...
    useEffect(() => {
        if (prophylactic === undefined) {
            navigate(`/prophylactics`)
        }
    }, [prophylactic, navigate])

    // ...
    if (prophylactic === undefined) {
        return null
    }

    const boxShadow = '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'

    return (
        <ProphylacticLayout>
            <Container style={{ paddingBottom: 30 }} >
                <IconButton onClick={() => navigate(`${location.pathname}/update`)} style={{ padding: 10, float: 'right', right: '5px', color: theme.palette.success.dark, backgroundColor: '#FFFFFF', marginTop: 20, boxShadow }}>
                    <EditPenIcon style={{ height: 26, width: 26 }} />
                </IconButton>

                <div style={{ paddingTop: 20 }}>
                    <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 5, color: theme.palette.primary.contrastText, float: 'left', padding: '2px 4px 2px 5px' }}>
                        <ProphylacticIcon style={{ height: 24, marginTop: 3 }} />
                    </div>
                    <h2 style={{ fontSize: '1.35em', paddingLeft: 10, paddingTop: 5, margin: 0, float: 'left' }}>{options.treatment.therapies[prophylactic.therapy_id].name}</h2>
                </div>
                <div style={{ clear: 'both' }}></div>

                <Grid  style={{ color: (prophylactic.status === 'active' ? theme.palette.success.dark : theme.palette.text.secondary ), fontWeight: 600, marginBottom: 5 }} alignContent="center" alignItems="center" container>
                    <Grid item xs={1}>{prophylactic.status === 'active' ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}</Grid>
                    <Grid item style={{ paddingBottom: 3 }} xs>{t('indexProphylactic'+ capitalize(prophylactic.status))}</Grid>
                </Grid>

                <Button onClick={() => navigate(`${location.pathname}/treatments`)} variant='contained' color='primary' style={{ borderRadius: 25, marginTop: 5, marginBottom: 15 }}><HealingIcon /><span style={{ marginLeft: 10 }}>{t('indexProphylacticTreatmentButton')}</span></Button>

                <Typography><b>{t('viewStartDate')}:</b></Typography>
                <Typography>{moment.unix(prophylactic.begin_at).format('LL')}</Typography>

                {prophylactic.units.length > 0 &&
                    <>
                        <h4 style={{ marginBottom: 0 }}>{t('viewTreatmentUnits')}</h4>
                        <ul style={{ padding: '0px 30px', lineHeight: 1.5, margin: 0 }}>
                            {prophylactic.units.map((unit, index) => {
                                return <li key={index}>{unit.amount} x {unit.unit_id > 0 ? options.treatment.therapies[prophylactic.therapy_id].units[unit.unit_id] : (unit.note ?? t('indexProphylacticTreatmentUnitEmpty'))}</li>
                            })}
                        </ul>
                    </>
                }

                <Grid style={{ marginTop: 20 }} container spacing={1}>
                    <Grid item>
                        <IconButton onClick={() => navigate(`${location.pathname}/update`, { state: { activeStep: 2 } })} style={{ padding: 10, backgroundColor: (prophylactic.remind_at_date > 0 ? theme.palette.success.dark : theme.palette.common.white), color: (prophylactic.remind_at_date > 0 ? theme.palette.common.white : ''), boxShadow }}>
                            <NotificationsIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setConfirmDelete(!confirmDelete)} style={{ padding: 10, backgroundColor: theme.palette.common.white, boxShadow }}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button onClick={() => navigate(`${location.pathname}/treatments/create`)} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('viewAddTreatment')}</span></Button>
            </Container>
            <div style={{ height: 76 }}></div>
            {confirmDelete && 
                <ConfirmDialog open={true} close={() => setConfirmDelete(!confirmDelete)} confirm={handleOnDelete} title={t('viewDeleteDialogHeader')} text={t('viewDeleteDialogText')} />
            }
        </ProphylacticLayout>
    )
}

export default ProphylacticView;
