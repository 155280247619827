import React from 'react';
import { Outlet, useParams } from 'react-router';

import ProphylacticIndex from '../../routes/private/ProphylacticIndex';
import { Dialog } from '@material-ui/core';
import useConsole from '../../hooks/useConsole';

function ProphylacticIndexLayout() {
    const console = useConsole()
    console.debug('route: ProphylacticIndexLayout');

    let { prophylactic_id } = useParams();

    return (
        <>
            <ProphylacticIndex />

            <Dialog fullScreen open={prophylactic_id !== undefined}>
                <Outlet />
            </Dialog>
        </>
    );
}

export default ProphylacticIndexLayout;
