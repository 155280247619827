import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Button, Container, Grid, IconButton, useTheme } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import HealingIcon from '@material-ui/icons/Healing'
import DeleteIcon from '@material-ui/icons/Delete'

import { ReactComponent as AttackIcon } from '../../icons/attacks.svg';
import { ReactComponent as AttackProgressIcon } from '../../icons/attack-progress.svg';
import { ReactComponent as EditPenIcon } from '../../icons/edit-pen.svg';

import AttackLayout from '../../layouts/private/AttackLayout'
import ConfirmDialog from '../../components/ConfirmDialog';

import useTranslate from '../../hooks/useTranslate';
import { attackState } from '../../data/attack';
import useApi from '../../hooks/useApi';
import { appOptionsState } from '../../data/app';
import useSafeArea from '../../hooks/useSafeArea';

function AttackView() {
    const location = useLocation()
    const theme = useTheme()
    const api = useApi()
    const t = useTranslate('attack');

    const navigate = useNavigate()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { attack_id } = useParams();
    const attack = useRecoilValue(attackState(attack_id))
    const options = useRecoilValue(appOptionsState)
    const { calcSab } = useSafeArea()

    // ...
    const arraySorting = (a, b) => {
        if (a === undefined || a.sorting === undefined || b === undefined || b.sorting === undefined) {
            return 0
        }

        return a.sorting > b.sorting ? 1 : -1;
    }

    // ...
    const handleOnDelete = async () => {
        await api.attackDelete(attack).then(() => {
            navigate(`/attacks`)
        })
    }

    // ...
    useEffect(() => {
        if (attack === undefined) {
            navigate(`/attacks`)
        }
    }, [attack, navigate])

    // ...
    if (attack === undefined) {
        return null
    }

    const boxShadow = '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'

    return (
        <AttackLayout>
            {/* <Button variant='contained' onClick={() => navigate(`${location.pathname}/treatments`)} style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 0, height: 55, boxShadow: 'none', borderBottom: '2px solid #ECECEE', textTransform: 'none', fontSize: '1.2em', color: theme.palette.text.secondary, fontWeight: 400 }}>
                <HealingIcon /><span style={{ marginLeft: 10 }}>Treatments</span>
            </Button> */}
            <Container style={{ paddingBottom: 30 }} >
                <IconButton onClick={() => navigate(`${location.pathname}/update`)} style={{ padding: 10, float: 'right', right: '5px', color: theme.palette.success.dark, backgroundColor: '#FFFFFF', marginTop: 20, boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}>
                    <EditPenIcon style={{ height: 26, width: 26 }} />
                </IconButton>

                <div style={{ paddingTop: 20 }}>
                    <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 5, color: theme.palette.primary.contrastText, float: 'left', padding: '2px 0px 2px 0px' }}>
                        <AttackIcon style={{ height: 24, marginTop: 3 }} />
                    </div>
                    <h2 style={{ fontSize: '1.35em', paddingLeft: 10, paddingTop: 5, margin: 0, float: 'left' }}>{moment.unix(attack.occurred_at).format('lll')}</h2>
                </div>
                <div style={{ clear: 'both' }}></div>

                <Button onClick={() => navigate(`${location.pathname}/treatments`)} variant='contained' color='primary' style={{ borderRadius: 25, marginTop: 10 }}><HealingIcon /><span style={{ marginLeft: 10 }}>Treatments</span></Button>

                {attack.areas.length > 0 &&
                    <>
                        <h4 style={{ marginBottom: 0 }}>{t('viewHeadlineAreasAffected')}</h4>
                        <ul style={{ padding: '0px 30px', lineHeight: 1.5, margin: 0 }}>
                            {attack.areas.slice().sort(arraySorting).map(area => {
                                if (options.attack.areas[area.id] === undefined) {
                                    return null
                                }
                                return <li key={area.id}>{options.attack.areas[area.id].name}{area.note ? ' - ' + area.note : ''}</li>
                            })}
                        </ul>
                    </>
                }

                {attack.symptoms.length > 0 && <>
                    <h4 style={{ marginBottom: 0 }}>{t('viewHeadlineSymptoms')}</h4>
                    <ul style={{ padding: '0px 30px', lineHeight: 1.5, margin: 0 }}>
                        {attack.symptoms.slice().sort(arraySorting).map(symptom => {
                            if (options.attack.symptoms[symptom.id] === undefined) {
                                return null
                            }
                            return <li key={symptom.id}>{options.attack.symptoms[symptom.id].name}{symptom.note ? ' - ' + symptom.note : ''}</li>
                        })}
                    </ul>
                </>}

                {attack.triggers.length > 0 && <>
                    <h4 style={{ marginBottom: 0 }}>{t('viewHeadlineTriggers')}</h4>
                    <ul style={{ padding: '0px 30px', lineHeight: 1.5, margin: 0 }}>
                        {attack.triggers.slice().sort(arraySorting).map(trigger => {
                            if (options.attack.triggers[trigger.id] === undefined) {
                                return null
                            }
                            return <li key={trigger.id}>{options.attack.triggers[trigger.id].name}{trigger.note ? ' - ' + trigger.note : ''}</li>
                        })}
                    </ul>
                </>}

                {(attack.improved_at !== 0 || attack.resolved_at !== 0) && <>
                    <div style={{ paddingTop: 20 }}>
                        <div style={{ color: '#268645', float: 'left' }}>
                            <AttackProgressIcon style={{ height: 18, marginTop: 2 }} />
                        </div>
                        <h3 style={{ paddingLeft: 5, paddingTop: 2, margin: 0, float: 'left' }}>{t('viewAttackProgress')}</h3>
                    </div>
                    <div style={{ clear: 'both' }}></div>

                    {attack.improved_at !== 0 && <>
                        <h4 style={{ marginBottom: 0, color: '#268645' }}>{t('viewImprovedAt')}</h4>
                        <p style={{ marginTop: 5 }}>{attack.improved_at ? moment.unix(attack.improved_at).format('LLL') : t('viewImprovedAtEmpty')}</p>
                    </>}

                    {attack.resolved_at !== 0 && <>
                        <h4 style={{ marginBottom: 0, color: '#268645' }}>{t('viewResolvedAt')}</h4>
                        <p style={{ marginTop: 5 }}>{attack.resolved_at ? moment.unix(attack.resolved_at).format('LLL') : t('viewResolvedAtEmpty')}</p>
                    </>}
                </>}

                {attack.affect > 0 &&
                    <>
                        <h4>{t('impactHeaderAffectActivitiesHours')}</h4>
                        <dl className='time-loss'>
                            {attack.affect_timeloss_work > 0 &&
                                <>
                                    <dt>{t('impactAffectActivitiesWorkLabel')}</dt>
                                    <dd>{attack.affect_timeloss_work ?? 0} {t('impactAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.affect_timeloss_study > 0 &&
                                <>
                                    <dt>{t('impactAffectActivitiesStudyLabel')}</dt>
                                    <dd>{attack.affect_timeloss_study ?? 0} {t('impactAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.affect_timeloss_sleep > 0 &&
                                <>
                                    <dt>{t('impactAffectActivitiesSleepLabel')}</dt>
                                    <dd>{attack.affect_timeloss_sleep ?? 0} {t('impactAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.affect_timeloss_other > 0 &&
                                <>
                                    <dt>{t('impactAffectActivitiesOtherLabel')}</dt>
                                    <dd>{attack.affect_timeloss_other ?? 0} {t('impactAffectTimelossHour')}</dd>
                                </>
                            }
                        </dl>
                        <div style={{ height: 0, clear: 'both' }}></div>
                    </>
                }

                {attack.caregiver_affect > 0 &&
                    <>
                        <h4 style={{ marginBottom: 5 }}>{t('impactHeaderCaregiverAffectActivitiesHours')}</h4>
                        <dl className='time-loss'>
                            {attack.caregiver_affect_timeloss_work > 0 &&
                                <>
                                    <dt>{t('impactCaregiverAffectActivitiesWorkLabel')}</dt>
                                    <dd>{attack.caregiver_affect_timeloss_work ?? 0} {t('impactCaregiverAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.caregiver_affect_timeloss_study > 0 &&
                                <>
                                    <dt>{t('impactCaregiverAffectActivitiesStudyLabel')}</dt><dd>{attack.caregiver_affect_timeloss_study ?? 0} {t('impactCaregiverAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.caregiver_affect_timeloss_sleep > 0 &&
                                <>
                                    <dt>{t('impactCaregiverAffectActivitiesSleepLabel')}</dt>
                                    <dd>{attack.caregiver_affect_timeloss_sleep ?? 0} {t('impactCaregiverAffectTimelossHour')}</dd>
                                </>
                            }
                            {attack.caregiver_affect_timeloss_other > 0 &&
                                <>
                                    <dt>{t('impactCaregiverAffectActivitiesOtherLabel')}</dt>
                                    <dd>{attack.caregiver_affect_timeloss_other ?? 0} {t('impactCaregiverAffectTimelossHour')}</dd>
                                </>
                            }
                        </dl>
                        <div style={{ clear: 'both' }}></div>
                    </>
                }
                <Grid style={{ marginTop: 20 }} container spacing={1}>
                    <Grid item>
                        <IconButton onClick={() => setConfirmDelete(!confirmDelete)} style={{ padding: 10, backgroundColor: theme.palette.common.white, boxShadow }}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button onClick={() => navigate(`${location.pathname}/treatments/create`)} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('viewAddTreatment')}</span></Button>
            </Container>
            <div style={{ height: 76 }}></div>
            {confirmDelete && 
                <ConfirmDialog open={true} close={() => setConfirmDelete(!confirmDelete)} confirm={handleOnDelete} title={t('viewDeleteDialogHeadline')} text={t('viewDeleteDialogText')} />
            }
        </AttackLayout>
    )
}

export default AttackView;
