import React, { useCallback, useState } from 'react';

import { List, ListSubheader, ListItem, Button } from '@material-ui/core';
import { ListItemText, Switch } from '@material-ui/core';


import DatePicker from './DatePicker';
import moment from 'moment';
import useTranslate from '../hooks/useTranslate';
import ListItemRadioDialog from './ListItemRadioDialog';
import TimePicker from './TimePicker';

import ProphylacticCustomSchedule from './ProphylacticCustomSchedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useRecoilValue } from 'recoil';
import { userState } from '../data/user';
import useApi from '../hooks/useApi';


export default function ProphylacticNotifications({ value, maxDate, onChange }) {
    const t = useTranslate('prophylactic');

    const [atDate, setAtDate] = useState((value && value.atDate) ? value.atDate : false);
    // const [remindDate, setRemindDate] = useState(moment().startOf('day').unix());
    const [atTime, setAtTime] = useState((value && value.atTime) ? value.atTime : false);
    // const [remindTime, setRemindTime] = useState(moment().startOf('day').unix());
    const [timestamp, setTimestamp] = useState((value && value.timestamp) ? value.timestamp : moment().startOf('day').unix());
    const [repeat, setRepeat] = useState((value && value.repeat) ? value.repeat : 'never');
    const [custom, setCustom] = useState((value && value.custom) ? value.custom : null);
    const [smsSuccess, setSmsSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const api = useApi()

    const user = useRecoilValue(userState)

    const handleTestSMS = () => {
        setSmsSuccess(null)
        setLoading(true)
        api.userTestSMS().then(data => {
            setSmsSuccess(data)
            setLoading(false)
        })
    }

    const handleOnChangeAtDate = () => {
        const value = !atDate;
        setAtDate(value)

        let _atTime = atTime;
        if (!value) {
            _atTime = value
            setAtTime(_atTime)
        }

        let _timestamp = timestamp
        if (!value) {
            _timestamp = moment().startOf('day').unix()
        }

        setTimestamp(_timestamp)


        let _repeat = repeat
        if (!value) {
            _repeat = 'never'
            setRepeat(_repeat)
        }

        let _custom = custom
        if (!value) {
            _custom = null
            setCustom(_custom)
        }

        handleOnChange(value, _atTime, _timestamp, _repeat, _custom)
    }

    const handleOnChangeAtTime = () => {
        const value = !atTime;
        setAtTime(value)

        let _atDate = atDate;
        if (value) {
            _atDate = value
            setAtDate(_atDate)
        }

        let _timestamp = timestamp
        if (!value) {
            _timestamp = moment.unix(_timestamp).startOf('day').unix()
            setTimestamp(_timestamp)
        }

        handleOnChange(!_atDate, value, _timestamp, repeat, custom)
    }

    const handleOnChangeTimestamp = (value) => {
        setTimestamp(value)
        handleOnChange(atDate, atTime, value, repeat, custom)
    }

    const handleOnChangeRepeat = (value) => {
        setRepeat(value)
        setCustom(null)
        handleOnChange(atDate, atTime, timestamp, value, null)
    }

    const handleOnChange = useCallback((atDate, atTime, timestamp, repeat, custom) => {
        if (typeof onChange === 'function') {
            onChange({ atDate, atTime, timestamp, repeat, custom })
        }
    }, [onChange])

    const handleOnChangeCustom = useCallback(value => {
        setCustom(value)
        handleOnChange(atDate, atTime, timestamp, repeat, value)
    }, [atDate, atTime, timestamp, repeat, handleOnChange])



    const options = {
        'never': t('remindRepeatNever'), //'Never',
        'hour': t('remindRepeatHourly'), //'Hourly',
        'day': t('remindRepeatDaily'), //'Daily',
        'weekday': t('remindRepeatWeekdays'), //'Weekdays',
        'weekend': t('remindRepeatWeekends'), //'Weekends',
        'week': t('remindRepeatWeekly'), //'Weekly',
        'biweek': t('remindRepeatBiweekly'), //'Biweekly',
        'month': t('remindRepeatMonthly'), //'Monthly',
        '3months': t('remindRepeat3Months'), //'Every 3 months',
        '6months': t('remindRepeat6Months'), //'Every 6 months',
        'year': t('remindRepeatYearly'), //'Yearly',
        'custom': t('remindRepeatCustom'), //'Custom',
    };


    if (!user.phone_country || String(user.phone_country) === '' || !user.phone || String(user.phone) === '') {
        return (
            <List>
                <ListSubheader disableSticky>{t('createReminderNoPhoneHeader')}</ListSubheader>
                <ListItem>{t('createReminderNoPhoneText')}</ListItem>
            </List>
        )
    }

    return (
        <>
            <List>
                <ListSubheader disableSticky>{t('createReminderHeader')}</ListSubheader>
                <ListItem onClick={handleOnChangeAtDate} button>
                    {/* Remind me on a day */}
                    <ListItemText id="switch-list-label-remind-date" primary={t('remindAtDate')} />
                    <Switch edge="end" checked={!!atDate} />
                </ListItem>
                {atDate &&
                    <ListItem>
                        <DatePicker value={timestamp} maxDate={maxDate} onChange={handleOnChangeTimestamp} />
                    </ListItem>
                }
                <ListItem onClick={handleOnChangeAtTime} button>
                    {/* Remind me at a time */}
                    <ListItemText id="switch-list-label-remind-time" primary={t('remindAtTime')} />
                    <Switch edge="end" checked={!!atTime} />
                </ListItem>
                {atTime &&
                    <ListItem>
                        <TimePicker value={timestamp} onChange={handleOnChangeTimestamp} />
                    </ListItem>
                }
                {(atDate || atTime) &&
                    // Repeat
                    <ListItemRadioDialog value={repeat} title={t('remindRepeat')} options={options} onChange={handleOnChangeRepeat} />
                }
                <ListItem>
                    <Button variant="contained" color="primary" onClick={handleTestSMS} disabled={smsSuccess || loading}>{t('sendTestSMS')}</Button>
                    {smsSuccess === true && <CheckCircleIcon style={{ marginLeft: '10px', color: "green" }} />}
                    {smsSuccess === false && <ErrorIcon  style={{ marginLeft: '10px', color: "red" }} />}
                </ListItem>
            </List>
            {
                repeat === 'custom' &&
                <ProphylacticCustomSchedule value={custom} onChange={handleOnChangeCustom} />
            }
        </>
    )
}
