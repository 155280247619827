import React, { useState } from 'react';

import ProphylacticLayout from '../../layouts/private/ProphylacticLayout'
import useTranslate from '../../hooks/useTranslate';

import 'format-unicorn';

import { List, ListSubheader, ListItem } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import mktime from '../../helpers/mktime';
import DatePicker from '../../components/DatePicker';
import TimePicker from '../../components/TimePicker';
import TherapyField from '../../components/TherapyField';
import StepForm from '../../components/StepForm';
import { useRecoilValue } from 'recoil';
import { prophylacticState } from '../../data/prophylactic';
import { userState } from '../../data/user';
import { useNavigate, useParams } from 'react-router';
import CheckboxList from '../../components/CheckboxList';
import useApi from '../../hooks/useApi';
import mapSideEffectsData from "../../helpers/mapSideEffectsData";
import { appOptionsState } from '../../data/app';
import useConsole from '../../hooks/useConsole';

/**
 * 
 * @param {*} prophylactic 
 * @param {*} treatment 
 * @returns 
 */
const getDefaultTherapy = (prophylactic, treatment) => {
    if (treatment) {
        return {
            therapy_id: treatment.therapy_id,
            units: treatment.units
        }
    }

    let _units = [];
    for (let index = 0; index < prophylactic.units.length; index++) {
        const element = prophylactic.units[index];
        _units.push({
            amount: element.amount,
            prophylactic_id: element.prophylactic_id,
            unit_id: element.unit_id,
            note: '',
            batches: []
        })
    }

    return {
        therapy_id: String(prophylactic?.therapy_id ?? ''),
        units: _units,
    }
}



/**
 * 
 * @param {*} treatment 
 * @returns 
 */
const getDefaultSelectedItemsAndNotes = (treatment) => {
    // ...
    let notes = {}
    let items = [];

    if (treatment !== undefined && Array.isArray(treatment.sideEffects)) {
        for (let index = 0; index < treatment.sideEffects.length; index++) {
            const element = treatment.sideEffects[index];
            notes[element.id] = element.note;
        }

        items = treatment.sideEffects.map(item => String(item.id));
    }

    return [items, notes]
}


function AttackTreatmentsCreate({ treatment }) {
    const t = useTranslate('treatment')
    const navigate = useNavigate()
    const api = useApi()
    const console = useConsole()

    let { prophylactic_id } = useParams();
    const prophylactic = useRecoilValue(prophylacticState(prophylactic_id))

    const options = useRecoilValue(appOptionsState)
    const { language_datetime } = useRecoilValue(userState)

    const [location, setLocation] = useState(treatment?.location_id ?? '')
    const [selectedDate, setSelectedDate] = useState(Math.floor(treatment?.treated_at ?? Date.now() / 1000))
    const [selectedTime, setSelectedTime] = useState(Math.floor(treatment?.treated_at ?? Date.now() / 1000))
    const defaultTherapy = getDefaultTherapy(prophylactic, treatment);
    const [therapy, setTherapy] = useState(defaultTherapy);
    const [selectedEffect, setSelectedEffect] = useState(treatment ? treatment.effect : 0);

    // ...
    const [defaultSelectedSideEffects, defaultSelectedSideEffectNotes] = getDefaultSelectedItemsAndNotes(treatment)
    const [selectedSideEffects, setSelectedSideEffects] = useState(defaultSelectedSideEffects)
    const [selectedSideEffectNotes, setSelectedSideEffectNotes] = useState(defaultSelectedSideEffectNotes)
    const handleSideEffectChange = (value) => {
        if (value.items !== undefined) {
            setSelectedSideEffects(value.items)
        }

        if (value.notes !== undefined) {
            setSelectedSideEffectNotes(value.notes)
        }
    }

    // ...
    const handleSubmit = () => {
        
        

        const data = {
            prophylactic_id: prophylactic.id,
            treated_at: mktime(language_datetime, selectedDate, selectedTime),
            location_id: location,
            therapy,
            effect: selectedEffect,
            side_effects: mapSideEffectsData(selectedEffect, selectedSideEffects, selectedSideEffectNotes),
        }


        if (treatment) {
            api.updateProphylacticTreatment(treatment, data).then(() => {
                navigate(`/prophylactics/${prophylactic.id}/treatments`);
            })
        } else {
            api.createProphylacticTreatment(data).then(() => {
                navigate(`/prophylactics/${prophylactic.id}/treatments`);
            })
        }
    }

    // ...
    const handleValidate = (activeStep) => {
        if (activeStep === 0) {
            return Number(location) > 0;
        }

        return true;
    }

    // ...
    if (prophylactic === undefined) {
        return null;
    }

    console.debug('route: ProphylacticTreatmentsCreate')

    return (
        <ProphylacticLayout>

            <StepForm onSubmit={handleSubmit} onValidate={handleValidate}>

                <List>
                    <ListSubheader disableSticky>{t('createHeadlineDateAndTime')}</ListSubheader>
                    <ListItem>
                        <DatePicker value={selectedDate} onChange={setSelectedDate} />
                    </ListItem>
                    <ListItem>
                        <TimePicker value={selectedTime} onChange={setSelectedTime} />
                    </ListItem>
                    <ListSubheader disableSticky>{t('createLocationHeader')}</ListSubheader>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('createLocationLabel')}</InputLabel>
                            <Select value={location} onChange={e => setLocation(e.target.value)} required>
                                {Object.entries(options.treatment.locations).map(item => {
                                    return <MenuItem style={{ whiteSpace: "normal" }} key={item[0]} value={item[0]}>{item[1].name}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('createLocationHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                </List>
                <TherapyField item={therapy} showBatch={true} type="prophylactic" editTherapy={false} onChange={setTherapy} />
                <>
                    <List>
                        <ListSubheader disableSticky>{t('editImpactEffectHeadline')}</ListSubheader>
                        <ListItem>
                            <FormControl fullWidth>
                                <InputLabel shrink id="demo-mutiple-checkbox-label">{t('editImpactEffectLabel')}</InputLabel>
                                <Select
                                    value={selectedEffect}
                                    onChange={e => setSelectedEffect(e.target.value)}
                                >
                                    {Object.entries(options.treatment.effects).map(item => {
                                        return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{t('editImpactEffectHint')}</FormHelperText>
                            </FormControl>
                        </ListItem>
                    </List>
                    {selectedEffect > 0 &&
                        <CheckboxList title={t('editImpactSideEffectsHeadline')} items={options.treatment.sideEffects} selectedItems={selectedSideEffects} selectedItemNotes={selectedSideEffectNotes} onChange={handleSideEffectChange} />
                    }
                </>
            </StepForm>



        </ProphylacticLayout>
    )
}

export default AttackTreatmentsCreate;
