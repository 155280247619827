import React, { useRef, useState } from 'react';

// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';

import { Stepper, Step, StepLabel, Button, Grid } from '@material-ui/core';
import { useLocation } from 'react-router';


function StepForm({ children, style, onNext, onPrevious, onSubmit, onValidate }) {
    const t = useTranslate('component')
    const location = useLocation()
    const [activeStep, setActiveStep] = useState(location.state?.activeStep ?? 0)
    const [switchStep, setSwitchStep] = useState(false)

    const ref = useRef()

    const renderSteps = () => {
        let steps = []
        for (let index = 0; index < children.length; index++) {
            steps.push(
                <Step key={index}>
                    <StepLabel></StepLabel>
                </Step>
            )
        }

        return steps;
    }

    const handleNextStep = async () => {
        setSwitchStep(true)

        let goToNext = true
        if (typeof onNext === 'function') {
            goToNext = await onNext(activeStep)
        }

        if (goToNext) {
            setActiveStep(activeStep + 1)
            ref.current.scrollIntoView();
        }

        setSwitchStep(false)
    }

    const handlePreviousStep = async () => {
        setSwitchStep(true)

        let goToPrevious = true
        if (typeof onPrevious === 'function') {
            goToPrevious = await onPrevious(activeStep)
        }

        if (goToPrevious) {
            setActiveStep(activeStep - 1)
            ref.current.scrollIntoView()
        }

        setSwitchStep(false)
    }

    const handleSubmit = () => {
        if (typeof onSubmit === 'function') {
            onSubmit()
        } else {
            console.warn('StepForm needs an onSubmit function');
        }
    }

    const getStepperWidth = () => {
        if (children.length === 2) {
            return '50%';
        } else if (children.length === 3) {
            return '75%';
        }
    }

    const getCurrentStep = () => {
        return Array.isArray(children) ? children[activeStep] : children;
    }

    const isValidStep = () => {
        return (typeof onValidate === 'function') ? onValidate(activeStep) : true
    }

    return (
        <div ref={ref} style={style}>
            {Array.isArray(children) &&
                <Stepper style={{ width: getStepperWidth(), margin: '0 auto' }} activeStep={activeStep}>
                    {renderSteps()}
                </Stepper>
            }
            {getCurrentStep()}
            <Grid style={{ width: '92%', margin: '20px auto 30px auto' }} container>
                <Grid item xs>
                    {(Array.isArray(children) && activeStep > 0) &&
                        <Button variant='outlined' size='large' disabled={switchStep} style={{ width: '90%', borderRadius: 25 }} onClick={handlePreviousStep}><KeyboardArrowLeftIcon /> {t('formDialogPrev')}</Button>
                    }
                </Grid>
                <Grid style={{ textAlign: 'right' }} item xs>
                    {(Array.isArray(children) && activeStep < (children.length - 1)) ?
                        <Button variant='contained' size='large' style={{ width: '90%', borderRadius: 25 }} disabled={!isValidStep() || switchStep} color='primary' onClick={handleNextStep}>{t('formDialogNext')} <KeyboardArrowRightIcon /></Button>
                        :
                        <Button variant='contained' size='large' style={{ width: '90%', borderRadius: 25 }} disabled={!isValidStep() || switchStep} color='primary' onClick={handleSubmit}>{t('formDialogSave')} <CheckIcon style={{ marginLeft: 5 }} /></Button>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default StepForm;
