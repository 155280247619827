import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useTranslate from '../../hooks/useTranslate';
import useApi from '../../hooks/useApi';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    subHeader: {
        fontSize: '1em'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function UserSignIn() {
    const t = useTranslate('user');
    const classes = useStyles();

    const { email } = useParams()

    const api = useApi()
    const navigate = useNavigate()

    const [inputEmail, setInputEmail] = useState(email ?? '');
    const [inputToken, setInputToken] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputPasswordAgain, setInputPasswordAgain] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            api.userResetPassword(email, inputToken, inputPassword).then(() => {
                navigate(`/`)
            }).catch(error => {
                console.error(error);
            })
        } else {
            api.userForgotPassword(inputEmail).then(() => {
                navigate(`/reset-password/${inputEmail}`)
            })
        }
    }

    const handleSetInputEmail = (value) => {
        setInputEmail(value);
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        setDisableSubmit(!pattern.test(value))
    }

    const handleSetInputPassword = (value) => {
        setInputPassword(value)
        setDisableSubmit(value.length < 8 || value === '' || inputPasswordAgain !== value)
    }

    const handleSetInputPasswordAgain = (value) => {
        setInputPasswordAgain(value)
        setDisableSubmit(value.length < 8 || value === '' || inputPassword !== value)
    }

    // ...
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">HAE TrackR</Typography>
                <Typography component="h2" className={classes.subHeader}>{t('resetPasswordHeader')}</Typography>
                <form className={classes.form}>
                    {email ? (
                        <p style={{ paddingBottom: 20 }}>{t('resetPasswordCheckEmailMessage')}</p>
                    ) : (
                        <p style={{ paddingBottom: 20 }}>{t('resetPasswordEnterYourEmailMessage')}</p>
                    )}
                    <Grid container spacing={2}>
                        {!email &&
                            <Grid item xs={12}>
                                <TextField
                                    defaultValue={inputEmail}
                                    onChange={e => handleSetInputEmail(e.target.value)}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    autoFocus
                                    autoComplete="off"
                                    label={t('resetPasswordEmailLabel')}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        }
                        {email &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        defaultValue={inputToken}
                                        onChange={e => setInputToken(e.target.value)}
                                        autoFocus
                                        autoComplete="new-password"
                                        variant="outlined"
                                        id="password-reset-token"
                                        name="password-reset-token"
                                        required
                                        fullWidth
                                        label="Reset token"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p>{t('resetPasswordRules')}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        defaultValue={inputPassword}
                                        onChange={e => handleSetInputPassword(e.target.value)}
                                        variant="outlined"
                                        autoComplete="new-password"
                                        required
                                        fullWidth
                                        label={t('resetPasswordNewPasswordLabel')}
                                        InputLabelProps={{ shrink: true }}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        defaultValue={inputPasswordAgain}
                                        onChange={e => handleSetInputPasswordAgain(e.target.value)}
                                        variant="outlined"
                                        autoComplete="new-password"
                                        required
                                        fullWidth
                                        label={t('resetPasswordNewPasswordAgainLabel')}
                                        InputLabelProps={{ shrink: true }}
                                        type="password"
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                    >{t('resetPasswordButton')}</Button>
                </form>
                <Grid container className={classes.form}>
                    <Grid item xs>
                        <Link to="/" variant="body2">{t('linkSignIn')}</Link>
                    </Grid>
                    <Grid item>
                        <Link to="/signup" variant="body2">{t('linkSignUp')}</Link>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
