import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { List, ListItem, ListSubheader, FormControl, InputLabel, FormHelperText, Select, MenuItem, Grid, TextField } from '@material-ui/core';

import { useRecoilValue } from 'recoil';
import useTranslate from '../hooks/useTranslate';
import { appOptionsState } from '../data/app';
import useConsole from '../hooks/useConsole';


function AttackImpact({ attack, onChange }) {
    const t = useTranslate('attack');

    const options = useRecoilValue(appOptionsState)
    const console = useConsole()

    const [selectedAffect, setSelectedAffect] = useState(attack?.affect ?? 0);
    const [selectedCaregiverAffect, setSelectedCaregiverAffect] = useState(attack?.caregiver_affect ?? 0);

    // ...
    const [affectHours, setAffectHours] = useState({
        work: attack?.affect_timeloss_work ?? 0,
        study: attack?.affect_timeloss_study ?? 0,
        sleep: attack?.affect_timeloss_sleep ?? 0,
        other: attack?.affect_timeloss_other ?? 0
    })

    // ...
    const [caregiverAffectHours, setCaregiverAffectHours] = useState({
        work: attack?.caregiver_affect_timeloss_work ?? 0,
        study: attack?.caregiver_affect_timeloss_study ?? 0,
        sleep: attack?.caregiver_affect_timeloss_sleep ?? 0,
        other: attack?.caregiver_affect_timeloss_other ?? 0
    })

    // ...
    const handleSelectedAffect = (type, value) => {
        const newValue = {...affectHours}
        newValue[type] = value;
        setAffectHours(newValue);
    }

    // ...
    const handleCaregiverAffectHours = (type, value) => {
        const newValue = {...caregiverAffectHours}
        newValue[type] = value;
        setCaregiverAffectHours(newValue);
    }

    // ...
    const handleChange2 = (value) => {
        setSelectedAffect(value);

        if (value === '0') {
            setAffectHours({ work: '0', study: '0', sleep: '0', other: '0' });
        }
    };

    // ...
    const handleChange3 = (value) => {
        setSelectedCaregiverAffect(value);

        if (value === '0') {
            setCaregiverAffectHours({ work: '0', study: '0', sleep: '0', other: '0' });
        }
    };

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange({
                affect: Number(selectedAffect),
                affect_timeloss_work: Number(affectHours.work),
                affect_timeloss_study: Number(affectHours.study),
                affect_timeloss_sleep: Number(affectHours.sleep),
                affect_timeloss_other: Number(affectHours.other),
                caregiver_affect: Number(selectedCaregiverAffect),
                caregiver_affect_timeloss_work: Number(caregiverAffectHours.work),
                caregiver_affect_timeloss_study: Number(caregiverAffectHours.study),
                caregiver_affect_timeloss_sleep: Number(caregiverAffectHours.sleep),
                caregiver_affect_timeloss_other: Number(caregiverAffectHours.other),
            })
        }
    }, [onChange, selectedAffect, affectHours.work, affectHours.study, affectHours.sleep, affectHours.other, selectedCaregiverAffect, caregiverAffectHours.work, caregiverAffectHours.study, caregiverAffectHours.sleep, caregiverAffectHours.other])

    console.debug('render: AttackImpact');

    return (
        <Box>
            <List style={{ padding: 0 }}>
                <ListSubheader disableSticky>{t('impactHeadlineAffectActivities')}</ListSubheader>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel shrink id="demo-mutiple-checkbox-label">{t('impactAffectActivitiesLabel')}</InputLabel>
                        <Select
                            value={selectedAffect}
                            onChange={e => handleChange2(e.target.value)}
                        >
                            {Object.entries(options.attack.affect).map(item => {
                                return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{t('impactAffectActivitiesHint')}</FormHelperText>
                    </FormControl>
                </ListItem>
            </List>
            {Number(selectedAffect) !== 0 && (
                <List style={{ padding: 0 }}>
                    <ListSubheader disableSticky>{t('impactHeaderAffectActivitiesHours')}</ListSubheader>
                    <ListItem>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={affectHours['work']} onChange={e => handleSelectedAffect('work', e.target.value)} label={t('impactAffectActivitiesHoursLabelWork')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactAffectActivitiesHoursHintWork')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={affectHours['study']} onChange={e => handleSelectedAffect('study', e.target.value)} label={t('impactAffectActivitiesHoursLabelStudy')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactAffectActivitiesHoursHintStudy')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={affectHours['sleep']} onChange={e => handleSelectedAffect('sleep', e.target.value)} label={t('impactAffectActivitiesHoursLabelSleep')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactAffectActivitiesHoursHintSleep')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={affectHours['other']} onChange={e => handleSelectedAffect('other', e.target.value)} label={t('impactAffectActivitiesHoursLabelOther')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactAffectActivitiesHoursHintOther')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            )}
            <List style={{ padding: 0 }}>
                <ListSubheader disableSticky>{t('impactHeadlineCaregiverAffectActivities')}</ListSubheader>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel shrink id="demo-mutiple-checkbox-label">{t('impactCaregiverAffectActivitiesLabel')}</InputLabel>
                        <Select
                            value={selectedCaregiverAffect}
                            onChange={e => handleChange3(e.target.value)}
                        >
                            {Object.entries(options.attack.caregiver_affect).map(item => {
                                return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>{t('impactCaregiverAffectActivitiesHint')}</FormHelperText>
                    </FormControl>
                </ListItem>
            </List>
            {Number(selectedCaregiverAffect) !== 0 && (
                <List style={{ padding: 0 }}>
                    <ListSubheader disableSticky>{t('impactHeaderCaregiverAffectActivitiesHours')}</ListSubheader>
                    <ListItem>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={caregiverAffectHours['work']} onChange={e => handleCaregiverAffectHours('work', e.target.value)} label={t('impactCaregiverAffectActivitiesHoursLabelWork')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactCaregiverAffectActivitiesHoursHintWork')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={caregiverAffectHours['study']} onChange={e => handleCaregiverAffectHours('study', e.target.value)} label={t('impactCaregiverAffectActivitiesHoursLabelStudy')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactCaregiverAffectActivitiesHoursHintStudy')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={caregiverAffectHours['sleep']} onChange={e => handleCaregiverAffectHours('sleep', e.target.value)} label={t('impactCaregiverAffectActivitiesHoursLabelSleep')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactCaregiverAffectActivitiesHoursHintSleep')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField type="number" defaultValue={caregiverAffectHours['other']} onChange={e => handleCaregiverAffectHours('other', e.target.value)} label={t('impactCaregiverAffectActivitiesHoursLabelOther')} InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>{t('impactCaregiverAffectActivitiesHoursHintOther')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            )}
        </Box>
    );
}

export default AttackImpact;
