import React from 'react';
// import { useRecoilValue } from 'recoil';
// import { useParams } from 'react-router';

// import { attackState } from '../data/selectors';
import ProphylacticCreate from './ProphylacticCreate';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { prophylacticState } from '../../data/prophylactic';

function ProphylacticUpdate() {

    let { prophylactic_id } = useParams();
    const prophylactic = useRecoilValue(prophylacticState(prophylactic_id))

    if (prophylactic === undefined) {
        return null;
    }

    return (
        <ProphylacticCreate prophylactic={prophylactic} />
    )
}

export default ProphylacticUpdate;
