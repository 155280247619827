import React, { useCallback, useState } from 'react';

import { List, ListItem, Select, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';

import ListItemRadioDialog from './ListItemRadioDialog';
import ListItemCheckboxDialog from './ListItemCheckboxDialog';
import { useEffect } from 'react';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';
import moment from 'moment';
import useConsole from '../hooks/useConsole';


export default function ProphylacticCustomSchedule({ value, onChange }) {

    const t = useTranslate('prophylactic');
    const console = useConsole()


    const [frequency, setFrequency] = useState((value && value.frequency) ? value.frequency : 'daily');


    const [config, setConfig] = useState((value && value.config) ? value.config : null);

    const handleSetFrequency = (value) => {
        setFrequency(value)
        setConfig(getDefaultValue(value))

        if (typeof onChange === 'function') {
            onChange({ frequency: value, config: getDefaultValue(value) })
        }
    }

    const handleSetConfig = useCallback((value) => {
        setConfig(value)
        if (typeof onChange === 'function') {
            onChange({ frequency, config: value })
        }
    }, [frequency, onChange])

    const getDefaultValue = (frequency) => {
        switch (frequency) {
            case 'daily':
                return 1
            case 'weekly':
                return {
                    [(new Date()).getDay()]: true
                }
            default:
                return null;
        }
    }

    const options = {
        'daily': t('remindRepeatFrequencyDaily'), //'Daily',
        'weekly': t('remindRepeatFrequencyWeekly'), //'Weekly',
    };

    const _options = {
        '1': moment().day(1).format('dddd'), //'Monday',
        '2': moment().day(2).format('dddd'), //'Tuesday',
        '3': moment().day(3).format('dddd'), //'Wednesday',
        '4': moment().day(4).format('dddd'), //'Thursday',
        '5': moment().day(5).format('dddd'), //'Friday',
        '6': moment().day(6).format('dddd'), //'Saterday',
        '0': moment().day(0).format('dddd'), //'Sunday',
    };

    const renderItems = () => {
        let items = []
        for (let index = 1; index <= 365; index++) {
            items.push(<MenuItem key={index} value={index}>{index} {index !== 1 ? t('remindRepeatFrequencyDailyPlural') : t('remindRepeatFrequencyDailySingular')}</MenuItem>)
        }

        return items
    }

    const handleCheckboxSort = (a, b) => {
        return +b === 0 ? -1 : 0
    }

    useEffect(() => {
        if (config === null) {
            handleSetConfig(getDefaultValue(frequency))
        }
    }, [config, frequency, handleSetConfig])

    console.debug('component: ProphylacticCustomSchedule');

    return (
        <List>
            {/* Frequency */}
            <ListItemRadioDialog value={frequency} title={t('remindRepeatFrequency')} options={options} onChange={value => handleSetFrequency(value)} />
            {frequency === 'daily' && config &&
                <ListItem>
                    <FormControl fullWidth>
                        {/* Every */}
                        <InputLabel shrink>{t('remindRepeatFrequencyDailyLabel')}</InputLabel>
                        <Select value={config} onChange={e => handleSetConfig(e.target.value)}>
                            {renderItems()}
                        </Select>
                    </FormControl>
                </ListItem>
            }
            {frequency === 'weekly' && config &&
                // Days
                <ListItemCheckboxDialog value={config} sort={handleCheckboxSort} title={t('remindRepeatDays')} options={_options} onChange={value => handleSetConfig(value)} />
            }
        </List>
    )
}
