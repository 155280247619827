import React from 'react';
import { Box } from '@material-ui/core';
import nl2br from '../helpers/nl2br';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import useTranslate from '../hooks/useTranslate';
import { userLastLoginState, userState } from '../data/user';
import { appNotificationsState } from '../data/app';

const useStyles = makeStyles((theme) => ({
    info: {
        fontSize: 12,
        padding: '10px 15px',
        color: '#0c5460',
        backgroundColor: '#d1ecf1',
        borderColor: '#bee5eb',
    }
}));

export default function AlertBox({ onClick, style }) {
    const { country, created_at } = useRecoilValue(userState)
    const last_login = useRecoilValue(userLastLoginState)
    const notifications = useRecoilValue(appNotificationsState)
    const t = useTranslate('alert');
    const classes = useStyles();

    const secSinceCreated = (Math.floor(Date.now() / 1000) - created_at);

    // show message 7 days from created_at
    if (country === '' && secSinceCreated <= (3600*24*7)) {
        return (
            <Box onClick={onClick ? onClick.userCountryEmpty : () => {}} style={style} className={classes.info}>{nl2br(t('userCountryEmpty'))}</Box>
        )
    } else if (last_login !== null) {
        for (let index = 0; index < notifications.length; index++) {
            const notification = notifications[index];
            if (notification.created_at > (last_login ?? 0) && notification.created_at > created_at) {
                return (
                    <Box className={classes.info} style={style} dangerouslySetInnerHTML={{ __html: notification.message }}></Box>
                )
            }
        }
    }

    return null;
}
