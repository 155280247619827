import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import { create } from "jss";
import rtl from "jss-rtl";
import { Capacitor } from '@capacitor/core';

import { StylesProvider, jssPreset } from '@material-ui/core/styles';

import { useRecoilValue } from 'recoil';
import { apiTokenState } from './data/app';
import { userState } from './data/user';

import Splash from './components/Splash';
import ThemePrivate from './components/ThemePrivate';
import ThemePublic from './components/ThemePublic';

import UserResetPassword from './routes/public/UserResetPassword';
import UserSignIn from './routes/public/UserSignIn';
import UserSignUp from './routes/public/UserSignUp';
import UserVerifyAccount from './routes/public/UserVerifyAccount';

import AttackLayout from './layouts/private/AttackLayout';
import AttackIndexLayout from './layouts/private/AttackIndexLayout';
import ProphylacticLayout from './layouts/private/ProphylacticLayout';
import ProphylacticIndexLayout from './layouts/private/ProphylacticIndexLayout';

import AttackIndex from './routes/private/AttackIndex';
import AttackView from './routes/private/AttackView';
import AttackCreate from './routes/private/AttackCreate';
import AttackUpdate from './routes/private/AttackUpdate';
import AttackTreatmentsIndex from './routes/private/AttackTreatmentsIndex';
import AttackTreatmentsCreate from './routes/private/AttackTreatmentsCreate';
import AttackTreatmentsUpdate from './routes/private/AttackTreatmentsUpdate';
import ProphylacticIndex from './routes/private/ProphylacticIndex';
import ProphylacticView from './routes/private/ProphylacticView';
import ProphylacticCreate from './routes/private/ProphylacticCreate';
import ProphylacticUpdate from './routes/private/ProphylacticUpdate';
import ProphylacticTreatmentsIndex from './routes/private/ProphylacticTreatmentsIndex';
import ProphylacticTreatmentsCreate from './routes/private/ProphylacticTreatmentsCreate';
import ProphylacticTreatmentsUpdate from './routes/private/ProphylacticTreatmentsUpdate';
import MoreIndex from './routes/private/MoreIndex';
import UserReports from './routes/private/UserReports';
import UserAccount from './routes/private/UserAccount';
import moment from 'moment';
import 'moment/min/locales';
// import { PushNotifications } from '@capacitor/push-notifications';
// import { LocalNotifications } from '@capacitor/local-notifications';
import { App } from '@capacitor/app';

/**
 * Handles /attacks/*
 * @returns Routes
 */
function AttackRoutes() {
    return (
        <Routes>
            <Route element={<AttackLayout />}>
                <Route element={<AttackIndexLayout />}>
                    <Route index element={<AttackIndex />} />
                    <Route path=":attack_id" element={<AttackView />} />
                    <Route path=":attack_id/update" element={<AttackUpdate />} />
                    <Route path=":attack_id/treatments" element={<AttackTreatmentsIndex />} />
                    <Route path=":attack_id/treatments/create" element={<AttackTreatmentsCreate />} />
                    <Route path=":attack_id/treatments/:treatment_id" element={<AttackTreatmentsUpdate />} />
                    {/* Maybe this should be changed to AttackTreatmentsView */}
                </Route>
            </Route>
            <Route path="create" element={<AttackCreate />} />
        </Routes>
    )
}

/**
 * Handles /prophylactics/*
 * @returns Routes
 */
function ProphylacticRoutes() {
    return (
        <Routes>
            <Route element={<ProphylacticLayout />}>
                <Route element={<ProphylacticIndexLayout />}>
                    <Route index element={<ProphylacticIndex />} />
                    <Route path=":prophylactic_id" element={<ProphylacticView />} />
                    <Route path=":prophylactic_id/update" element={<ProphylacticUpdate />} />
                    <Route path=":prophylactic_id/treatments" element={<ProphylacticTreatmentsIndex />} />
                    <Route path=":prophylactic_id/treatments/create" element={<ProphylacticTreatmentsCreate />} />
                    <Route path=":prophylactic_id/treatments/:treatment_id" element={<ProphylacticTreatmentsUpdate />} />
                    {/* Maybe this should be changed to ProphylacticTreatmentsView */}
                </Route>
            </Route>
            <Route path="create" element={<ProphylacticCreate />} />
        </Routes>
    )
}

/**
 * Handles /* when user is not logged in
 * @returns Routes
 */
function PublicRoutes() {
    return (
        <ThemePublic direction="ltr" >
            <Routes>
                <Route index element={<UserSignIn />} />
                <Route path="/signup" element={<UserSignUp />} />
                <Route path="/verify-account" element={<UserVerifyAccount />} />
                <Route path="/reset-password/:email?" element={<UserResetPassword />} />
                <Route path="/verify-account/:email" element={<UserVerifyAccount />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </ThemePublic>
    )
}

/**
 * Handles /* when user is logged in
 * @returns Routes
 */
function PrivateRoutes() {
    const { direction } = useRecoilValue(userState);
    document.getElementsByTagName('html')[0].setAttribute("dir", direction);

    const { language_datetime, start_screen } = useRecoilValue(userState)
    moment.locale(language_datetime)

    const navigate = useNavigate()

    let navigateTo = ''
    switch (start_screen) {
        case 'prophylaxis':
            navigateTo = 'prophylactics'
            break;
        case 'attack':
            navigateTo = 'attacks'
            break;
        default:
            navigateTo = 'attacks'
            break;
    }

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {

            App.addListener('appUrlOpen', event => {
                const url = new URL(event.url)
                // console.log('appUrlOpen', event);
                // console.log(url.pathname);
                navigate(url.pathname)
            })

            // PushNotifications.addListener('pushNotificationReceived', (notification) => {
            //     LocalNotifications.schedule([{ id: Date.now(), title: notification.title, body: notification.body, ongoing: false }]);
            // });

            // PushNotifications.addListener('pushNotificationActionPerformed', (event) => {
            //     if (event.notification.data.navigate !== undefined) {
            //         navigate(event.notification.data.navigate)
            //     }
            // });
        }
    }, [navigate])

    return (
        <div className="fullHeight">
            <ThemePrivate direction={direction}>
                <Routes>
                    <Route path="/" element={<Outlet />}>
                        <Route index element={<Navigate to={`/${navigateTo}`} />} />
                        <Route path="attacks/*" element={<AttackRoutes />} />
                        <Route path="prophylactics/*" element={<ProphylacticRoutes />} />
                        <Route path="more" element={<MoreIndex />} />
                    </Route>
                    <Route path="/user/reports" element={<UserReports />} />
                    <Route path="/user/account" element={<UserAccount />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </ThemePrivate>
        </div>
    )
}

function MyApp() {
    const apiToken = useRecoilValue(apiTokenState)
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    return (
        <StylesProvider jss={jss}>
            <Suspense fallback={<></>}>
                {!Capacitor.isNativePlatform() &&
                    <Suspense fallback={<></>}>
                        <Splash />
                    </Suspense>
                }
                {apiToken === null ? (
                    <PublicRoutes />
                ) : (
                    <PrivateRoutes />
                )}
            </Suspense>
        </StylesProvider>
    )
}

export default MyApp;
