import React, { useRef } from 'react';

import { List, ListItem, ListSubheader, ListItemIcon } from '@material-ui/core';
import { ListItemText, Checkbox } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { TextField } from '@material-ui/core';
// import { Capacitor } from '@capacitor/core';


function MyFormHelperText(props) {
    return (props.children !== '') ? <FormHelperText>{props.children}</FormHelperText> : '';
}

function CustomInput({ items, onChange, onChangeNote, value, selectedItems, selectedItemNotes }) {

    const inputRef = useRef();

    const handleOnChange = (value) => {
        onChange(value)

        if (items[value].support_notes && selectedItems.indexOf(value) === -1) {
            setTimeout(() => {
                inputRef.current.scrollIntoView();
                setTimeout(() => {
                    inputRef.current.focus();
                }, 500);
            }, 200);
        }
    }

    const handleOnChangeNote = (e) => {
        onChangeNote(value[0], e.target.value)
    }

    return (
        <>
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => handleOnChange(value[0])} button >
                <ListItemIcon>
                    <Checkbox
                        edge="end"
                        onChange={() => handleOnChange(value[0])}
                        checked={selectedItems.indexOf(value[0]) !== -1}
                        color='primary'
                    />
                </ListItemIcon>
                <ListItemText primary={value[1].name} />
                {/* <ListItemSecondaryAction>
                    
                </ListItemSecondaryAction> */}
            </ListItem>
            {value[1].support_notes &&
                <ListItem style={{ display: selectedItems.indexOf(value[0]) === -1 ? 'none' : 'block'}}>
                    <FormControl fullWidth>
                        <TextField
                            label={"'" + value[1].name + "' note"}
                            InputLabelProps={{ shrink: true }}
                            autoComplete='off'
                            onChange={handleOnChangeNote}
                            defaultValue={selectedItemNotes[value[0]]}
                            inputRef={inputRef}
                        />
                        <MyFormHelperText>...</MyFormHelperText>
                    </FormControl>
                </ListItem>
            }
        </>
    )
}

export default function CheckboxList({ selectedItems, selectedItemNotes, items, onChange, title }) {
    // const [focus, setFocus] = useState(null);

    // ...
    const handleSelected = (value) => {
        const current = selectedItems.indexOf(value);
        const checked = [...selectedItems];

        if (current === -1) {
            checked.push(value);

            // if (items[value].support_notes) {
            //     setFocus(value)
            // }
        } else {
            checked.splice(current, 1);
        }

        onChange({
            items: checked,
            notes: selectedItemNotes
        });
    };

    const handleSelectedNote = (item, note) => {

        selectedItemNotes[item] = note

        onChange({
            items: selectedItems,
            notes: selectedItemNotes
        });
    }

    if (!items) {
        return null;
    }
    
    const arraySorting = (a, b) => {
        if (a[1] === undefined || a[1].sorting === undefined) {
            return 0
        }

        return a[1].sorting > b[1].sorting ? 1 : -1;
    }

    return (
        <List>
            <ListSubheader disableSticky>{title}</ListSubheader>
            {Object.entries(items).sort(arraySorting).map(value => {
                return <CustomInput key={value[0]} value={value} items={items} selectedItems={selectedItems} onChange={handleSelected} onChangeNote={handleSelectedNote} selectedItemNotes={selectedItemNotes} />
            })}
        </List>
    );
}
