import React from 'react';
// import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
// import AttackIcon from '../../icons/attacks.svg';
// import { ReactComponent as AttackIcon } from '../../icons/attacks.svg';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


import { Typography, AppBar, Toolbar } from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';

// import moment from 'moment';
// import 'moment/locale/da';
// import 'moment/locale/en-gb';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';

import { useLocation, useNavigate, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { attackState } from '../data/attack';
import { useTheme } from '@material-ui/styles';
import useTranslate from '../hooks/useTranslate';
import useSafeArea from '../hooks/useSafeArea';

const useStyles = makeStyles((theme) => ({
    top: {
        marginTop: 64,
    },
    grow: {
        flexGrow: 1,
    },
    list: {
        minWidth: 200,
        padding: 0
    },
    listItem: {
        paddingRight: 32
    },
    listItemIcon: {
        minWidth: 40
    },
    btnPlaceholder: {
        height: 48,
        width: 48,
    },
    circleButton: {
        border: '1px solid',
        padding: '8px 7px',
        fontSize: 14,
        width: 34
    }
}));

export default function AppBarMore() {

    const t = useTranslate('layout')
    const classes = useStyles()
    const location = useLocation()
    const theme = useTheme()
    const { sat } = useSafeArea()

    let { attack_id } = useParams()

    const attack = useRecoilValue(attackState(attack_id))


    const navigate = useNavigate()

    const handleNavigate = () => {
        let path = -1;
        if (location.pathname === `/attacks/${attack.id}`) {
            path = `/attacks`
        } else if (location.pathname === `/attacks/${attack.id}/treatments`) {
            path = `/attacks/${attack.id}`
        }

        navigate(path)
    }
    
    const appBarText = () => {
        if (location.pathname === '/user/reports') {
            return t('userReportsRoute')
        } else if (location.pathname === '/user/account') {
            return t('userAccountRoute')
        }

        return t('indexMenuTabMore')
    }

    return (
        <AppBar id='topAppBar' position="fixed" style={{ paddingTop: sat }}>
            <Toolbar>
                {attack ?
                    <IconButton onClick={handleNavigate} edge="start" color="inherit">
                        <KeyboardArrowLeftIcon style={{ backgroundColor: '#FFFFFF', color: theme.palette.primary.main, borderRadius: 4 }} />
                    </IconButton>
                :
                    <div style={{ width: 48 }} />
                }
                <div className={classes.grow} />
                {attack ?
                    <Typography variant="h6" noWrap>{moment.unix(attack.occurred_at).format('ll')}</Typography>
                    :
                    <Typography variant="h6" noWrap>{appBarText()}</Typography>
                }
                <div className={classes.grow} />
                <div style={{ width: 48 }} />
            </Toolbar>
        </AppBar>
    );
}
