import { useRecoilValue } from "recoil";
import { appState } from "../data/app";

function useConsole() {

    const app = useRecoilValue(appState)

    const debug = (...data) => {
        if (app.state !== 'prod') {
            console.debug(...data)
        }
    }

    const log = (...data) => {
        if (app.state !== 'prod') {
            console.log(...data)
        }
    }

    const warn = (...data) => {
        if (app.state !== 'prod') {
            console.warn(...data)
        }
    }

    return {
        debug,
        log,
        warn
    }
}

export default useConsole
