import React, { useEffect, useState } from "react";
import {
	Box,
	Container,
	IconButton,
	Grid,
	FormHelperText,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import useTranslate from "../../hooks/useTranslate";
import Alert from "@material-ui/lab/Alert";
import nl2br from "../../helpers/nl2br";
import { useRecoilValue } from "recoil";
import { appLanguageState, appState } from "../../data/app";
import useApi from "../../hooks/useApi";
import StepForm from "../../components/StepForm";
import InputFieldText from "../../components/InputFieldText";
import InputFieldSelect from "../../components/InputFieldSelect";
import LanguageSwitch from "../../components/LanguageSwitch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { userOptionsState } from "../../data/user";
import useSafeArea from "../../hooks/useSafeArea";

export default function UserSignUp() {
	const t = useTranslate("user");
	const api = useApi();
	const navigate = useNavigate();
	const theme = useTheme();

	const { language } = useRecoilValue(appLanguageState);
	const { state } = useRecoilValue(appState);
	const userOptions = useRecoilValue(userOptionsState);
	const { calcSat } = useSafeArea();

	const [showTerms, setShowTerms] = useState(
		state === "local" ? false : true,
	);

	const uniqid = (prefix = "", random = false) => {
		const sec = Date.now() * 1000 + Math.random() * 1000;
		const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
		return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ""}`;
	};

	const _uniqid = uniqid();

	const [inputFirstName, setInputFirstName] = useState(
		state === "local" ? "Test" : "",
	);
	const [inputLastName, setInputLastName] = useState(
		state === "local" ? "Test" : "",
	);
	const [inputCountry, setInputCountry] = useState(
		state === "local" ? "dk" : "",
	);
	const [inputPhoneCountry, setInputPhoneCountry] = useState(
		state === "local" ? "45" : "",
	);
	const [inputPhone, setInputPhone] = useState(
		state === "local" ? "12345678" : "",
	);

	const [verifyTokenType, setVerifyTokenType] = useState("phone");
	const [inputToken, setInputToken] = useState("");
	const [inputEmail, setInputEmail] = useState(
		state === "local" ? `henrik+${_uniqid}@netkant.com` : "",
	);
	const [inputEmailAgain, setInputEmailAgain] = useState(
		state === "local" ? `henrik+${_uniqid}@netkant.com` : "",
	);
	const [inputPassword, setInputPassword] = useState(
		state === "local" ? "NetkantTest1234!" : "",
	);
	const [inputPasswordAgain, setInputPasswordAgain] = useState(
		state === "local" ? "NetkantTest1234!" : "",
	);
	const [phoneTokenUniqid, setPhoneTokenUniqid] = useState("");
	const [error, setError] = useState(false);
	const [countdown, setCountdown] = useState(0);

	/**
	 *
	 * @returns array
	 */
	const mapUserPhoneCountries = () => {
		return Object.entries(userOptions.phone_countries).map((option) => {
			return { key: option[0], value: option[1] };
		});
	};

	/**
	 *
	 * @returns array
	 */
	const mapUserCountries = () => {
		return Object.entries(userOptions.countries).map((option) => {
			return { key: option[0], value: option[1] };
		});
	};

	/**
	 *
	 * @param {string} str
	 * @returns bool
	 */
	const validateEmail = (str) => {
		// const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		const pattern = new RegExp(
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		);
		return str.length >= 8 && pattern.test(str);
	};

	/**
	 *
	 * @returns bool
	 */
	const sendVerificationToken = async () => {
		setPhoneTokenUniqid("");
		setInputToken("");
		const response = await api
			.userRequestVerifyPhoneToken(inputPhoneCountry, inputPhone)
			.then((response) => {
				setCountdown(state === "local" ? 10 : 60);
				setPhoneTokenUniqid(response.data);
				setVerifyTokenType("phone");
				return response;
			});

		return response.success;
	};

	/**
	 *
	 * @returns bool
	 */
	const sendEmailVerificationToken = async () => {
		setPhoneTokenUniqid("");
		setInputToken("");
		const response = await api
			.userRequestVerifyEmailToken(inputEmailAgain)
			.then((response) => {
				setCountdown(state === "local" ? 10 : 60);
				setPhoneTokenUniqid(response.data);
				setVerifyTokenType("email");
				return response;
			});

		return response.success;
	};

	const checkMail = async () => {
		try {
			const response = await api.userCheckMail(inputEmail);
			if (!response.success) {
				setError("Email has already been taken.");
			}
			return response.success;
		} catch (error) {
			return false;
		}
	};

	/**
	 *
	 * @param {*} activeStep
	 * @returns bool
	 */
	const handleOnPrevious = (activeStep) => {
		setError(null);
		return true;
	};

	/**
	 *
	 * @param {*} activeStep
	 * @returns bool
	 */
	const handleOnNext = async (activeStep) => {
		setError(null);

		if (activeStep === 0) {
			return await checkMail();
		} else if (activeStep === 1) {
			return await sendVerificationToken();
		}

		return true;
	};

	/**
	 *
	 * @param {*} activeStep
	 * @returns bool
	 */
	const handleOnValidate = (activeStep) => {
		if (activeStep === 0) {
			return (
				validateEmail(inputEmail) &&
				inputEmail === inputEmailAgain &&
				inputPassword.length >= 8 &&
				inputPassword === inputPasswordAgain
			);
		} else if (activeStep === 1) {
			return (
				inputFirstName.length > 0 &&
				inputLastName.length > 0 &&
				inputCountry !== "" &&
				inputPhone.length > 0
			);
		} else if (activeStep === 2) {
			return inputToken.length >= 6 && phoneTokenUniqid.length !== 0;
		}

		return true;
	};

	/**
	 *
	 */
	const handleOnSubmit = async () => {
		setError(null);

		const response =
			verifyTokenType === "email"
				? await api.userVerifyEmailToken(phoneTokenUniqid, inputToken)
				: await api.userVerifyPhoneToken(phoneTokenUniqid, inputToken);

		if (response.success) {
			const data = {
				first_name: inputFirstName,
				last_name: inputLastName,
				email: inputEmail,
				password: inputPassword,
				phone: inputPhone,
				phone_country: inputPhoneCountry,
				country: inputCountry,
				language,
			};

			await api
				.userCreate(data)
				.then(() => {
					api.userLogin(inputEmail, inputPassword).then(() => {
						navigate(`/`);
					});
				})
				.catch((error) => {
					setError(error);
				});
		} else {
			setError(
				"Failed to verify your phone number, please check your token is correct.",
			);
		}
	};

	/**
	 *
	 */
	useEffect(() => {
		if (!countdown > 0) {
			return;
		}

		const timeout = setInterval(() => {
			setCountdown(countdown - 1);
		}, 1000);

		return () => clearInterval(timeout);
	}, [countdown]);

	/**
	 *
	 */
	return (
		<Box maxWidth="xs" style={{ padding: "0 9px", paddingTop: calcSat(10) }}>
			<Grid container>
				<Grid xs={3} item>
					<IconButton
						style={{ marginLeft: 3 }}
						onClick={() => navigate(`/`)}
						edge="start"
						color="inherit"
					>
						<KeyboardArrowLeftIcon
							style={{
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.primary.contrastText,
								borderRadius: 4,
							}}
						/>
					</IconButton>
				</Grid>
				<Grid style={{ textAlign: "center", paddingTop: 30 }} xs={6} item>
					<img
						alt="App logo"
						src="/logo192.png"
						style={{ width: 140, height: "auto" }}
					/>
				</Grid>
				<Grid xs={3} item>
					<Box style={{ textAlign: "right" }}>
						<LanguageSwitch />
					</Box>
				</Grid>
			</Grid>
			{error && (
				<Alert style={{ marginBottom: 0, marginTop: 20 }} severity="error">
					{error}
				</Alert>
			)}
			<StepForm
				onNext={handleOnNext}
				onValidate={handleOnValidate}
				onPrevious={handleOnPrevious}
				onSubmit={handleOnSubmit}
			>
				<Container>
					<InputFieldText
						name="email"
						value={inputEmail}
						label={t("signUpEmailLabel")}
						onChange={setInputEmail}
						autoFocus
					/>
					<InputFieldText
						name="email_again"
						value={inputEmailAgain}
						label={t("signUpEmailAgainLabel")}
						onChange={setInputEmailAgain}
					/>
					<InputFieldText
						name="password"
						type="password"
						value={inputPassword}
						label={t("signUpPasswordLabel")}
						onChange={setInputPassword}
						hint={t("resetPasswordRules")}
					/>
					<InputFieldText
						name="password_again"
						type="password"
						value={inputPasswordAgain}
						label={t("signUpPasswordAgainLabel")}
						onChange={setInputPasswordAgain}
					/>

					<Dialog
						open={showTerms}
						onClose={() => {}}
						scroll="paper"
						PaperProps={{
							style: {
								maxHeight: "calc(100% - (64px + " + calcSat(10) + "px))",
							},
						}}
					>
						<DialogTitle>{t("termsAndPrivacyHeader")}</DialogTitle>
						<DialogContent>
							<DialogContentText tabIndex={-1}>
								{nl2br(t("termsAndPrivacyText"))}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => navigate("/")}
								variant="outlined"
								color="secondary"
							>
								{t("termsAndPrivacyCancel")}
							</Button>
							<Button
								onClick={() => setShowTerms(!showTerms)}
								variant="outlined"
								color="primary"
							>
								{t("termsAndPrivacyAccept")}
							</Button>
						</DialogActions>
					</Dialog>
				</Container>
				<Container>
					<Grid spacing={2} container>
						<Grid item xs={6}>
							<InputFieldText
								name="first_name"
								value={inputFirstName}
								label={t("signUpFirstNameLabel")}
								onChange={setInputFirstName}
								autoFocus
							/>
						</Grid>
						<Grid item xs={6}>
							<InputFieldText
								name="last_name"
								value={inputLastName}
								label={t("signUpLastNameLabel")}
								onChange={setInputLastName}
							/>
						</Grid>
					</Grid>
					<InputFieldSelect
						name="country"
						value={inputCountry}
						options={mapUserCountries()}
						label={t("signUpCountryLabel")}
						onChange={setInputCountry}
					/>
					<Grid spacing={2} container>
						<Grid item xs={4}>
							<InputFieldSelect
								name="phone_country"
								value={inputPhoneCountry}
								options={mapUserPhoneCountries()}
								label={t("signUpPhoneCountryLabel")}
								onChange={setInputPhoneCountry}
							/>
						</Grid>
						<Grid item xs={8}>
							<InputFieldText
								name="phone"
								type="number"
								value={inputPhone}
								label={t("signUpPhoneLabel")}
								onChange={setInputPhone}
							/>
						</Grid>
					</Grid>
					<FormHelperText>{t("signUpPhoneHint")}</FormHelperText>
				</Container>
				<Container>
					{verifyTokenType === "phone" && (
						<InputFieldText
							type="number"
							value={inputToken}
							label={t("signUpPhoneTokenLabel")}
							hint={t("signUpPhoneTokenHint").formatUnicorn({
								phone: `+${inputPhoneCountry}${inputPhone}`,
							})}
							onChange={setInputToken}
						/>
					)}

					{verifyTokenType === "email" && (
						<InputFieldText
							type="number"
							value={inputToken}
							label={t("signUpEmailTokenLabel")}
							hint={t("signUpEmailTokenHint").formatUnicorn({
								email: inputEmail,
							})}
							onChange={setInputToken}
						/>
					)}

					<Grid spacing={3} style={{ padding: '10px 0 10px 0' }} container>
						{countdown === 0 && (
							<Grid xs={12} item>
								<Button onClick={sendVerificationToken} variant="contained" fullWidth>
									{t("signUpResendVerificationCode")}
								</Button>
							</Grid>
						)}
						{countdown === 0 && (
							<Grid xs={12} item>
								<Button
									onClick={sendEmailVerificationToken}
									variant="contained"
                                    fullWidth
								>
									{t("signUpSendEmailVerificationCode")}
								</Button>
							</Grid>
						)}
						{countdown > 0 && (
							<Grid xs={12} style={{ textAlign: 'center' }} item>
								{nl2br(t("signUpResendVerificationCodeCountdown").formatUnicorn({
									countdown,
								}))}
							</Grid>
						)}
					</Grid>
				</Container>
			</StepForm>
		</Box>
	);
}
