import React from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate, useParams } from 'react-router';

import { prophylacticState } from '../../data/prophylactic';
import ProphylacticLayout from '../../layouts/private/ProphylacticLayout';
import { Button, Card, Container, useTheme } from '@material-ui/core';
import useTranslate from '../../hooks/useTranslate';
import AddIcon from '@material-ui/icons/Add';
import TreatmentCardContent from '../../components/TreatmentCardContent';
import useSafeArea from '../../hooks/useSafeArea';

function ProphylacticTreatmentsIndex() {

    const t = useTranslate('prophylactic')
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const { calcSab } = useSafeArea()

    let { prophylactic_id } = useParams();
    const prophylactic = useRecoilValue(prophylacticState(prophylactic_id))
    
    if (prophylactic === undefined) {
        return null;
    }

    return (
        <ProphylacticLayout>
            {prophylactic.treatments.length > 0 ? prophylactic.treatments.map((treatment, index) => (
                <Card key={index} onClick={() => navigate(`/prophylactics/${prophylactic.id}/treatments/${treatment.id}`)} style={{ margin: 20, borderRadius: 10 }} raised>
                    <TreatmentCardContent treatment={treatment} />
                </Card>
            )) : ''}
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button onClick={() => navigate(`${location.pathname}/create`)} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('viewAddTreatment')}</span></Button>
            </Container>
        </ProphylacticLayout>
    )
}

export default ProphylacticTreatmentsIndex;
