import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';


function ListItemRadioDialogRaw(props) {
    const { onClose, title, options, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    const t = useTranslate('component')

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            fullWidth
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            onClose={handleCancel}
            {...other}
        >
            <DialogTitle onClose={handleCancel}>{title}</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {Object.keys(options).map((optionKey) => (
                        <FormControlLabel
                            value={optionKey}
                            key={optionKey}
                            control={<Radio />}
                            label={options[optionKey]}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>{t('formRadioDialogOK')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function ListItemRadioDialog({ title, value, options, onChange }) {
    const [open, setOpen] = React.useState(false);
    const [_value, setValue] = React.useState(value);

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
            if (typeof onChange === 'function') {
                onChange(newValue)
            }
        }
    };

    return (
        <>
            <ListItem
                button
                aria-haspopup="true"
                aria-controls="ringtone-menu"
                aria-label="phone ringtone"
                onClick={handleClickListItem}
            >
                <ListItemText primary={title} secondary={options[_value]} />
            </ListItem>
            <ListItemRadioDialogRaw
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                value={_value}
                title={title}
                options={options}
            />
        </>
    );
}
