import React from 'react';
import { Button, Card, CardContent, useTheme } from '@material-ui/core';
import moment from 'moment';

import { ReactComponent as AttackIcon } from '../icons/attacks.svg';
import HealingIcon from '@material-ui/icons/Healing';

import { useRecoilValue } from 'recoil';
import { attacksState } from '../data/attack';
import { useNavigate } from 'react-router';
import useTranslate from '../hooks/useTranslate';
import { appOptionsState } from '../data/app';
import useConsole from '../hooks/useConsole';

function AttackIndexList() {
    const t = useTranslate('attack')
    const navigate = useNavigate()
    const theme = useTheme()
    const console = useConsole()

    const attacks = useRecoilValue(attacksState)
    const options = useRecoilValue(appOptionsState)
    
    console.debug('component: AttackIndexList');

    return (
        <>
            {attacks.map((attack, index) => {
                const _areas = attack.areas.map(area => {
                    if (options.attack.areas[area.id] === undefined) {
                        return null
                    }
                    return options.attack.areas[area.id].name + (area.note ? ' - ' + area.note : '');
                })

                return (
                    <Card onClick={() => navigate(`/attacks/${attack.id}`)} style={{ margin: 20, borderRadius: 10 }} raised key={index}>
                        <CardContent>
                            <div>
                                <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 5, color: theme.palette.primary.contrastText, float: 'left', padding: '2px 0px 2px 0px' }}>
                                    <AttackIcon style={{ height: 24, marginTop: 2 }} />
                                </div>
                                <h3 style={{ paddingLeft: 10, paddingTop: 5, margin: 0, float: 'left' }}>{moment.unix(attack.occurred_at).format('LLL')}</h3>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                            {_areas.length > 0 &&
                                <>
                                    <h4 style={{ marginBottom: 0 }}>{t('viewHeadlineAreasAffected')}</h4>
                                    <ul style={{ margin: 0, color: '#575757', paddingLeft: 25, lineHeight: 1.5 }}>
                                        {_areas.map((area, index) => (
                                            <li style={{}} key={index}>{area}</li>
                                        ))}
                                    </ul>
                                </>
                            }
                            <Button onClick={e => { e.stopPropagation(); navigate(`/attacks/${attack.id}/treatments/create`) }} style={{ borderWidth: 2, borderRadius: 20, marginTop: 20, textTransform: 'none', borderColor: '#575757', color: '#575757' }} variant="outlined"><HealingIcon />&nbsp;&nbsp;{t('viewAddTreatment')}</Button>
                        </CardContent>
                    </Card>
                )
            })}
            {/* { attacks.length >= 2 && showLoadMore &&
                <Box textAlign="center">
                    <Button size='small' onClick={handleLoadMore} variant='outlined'>Load more</Button>
                </Box>
            } */}
            
        </>
    );
}

export default AttackIndexList;
