import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router';

import { List, ListSubheader, ListItem } from '@material-ui/core';

import CheckboxList from '../../components/CheckboxList';
import DateTimePicker from '../../components/DateTimePicker';
import StepForm from '../../components/StepForm';
import AttackImpact from '../../components/AttackImpact';

import AttackLayout from '../../layouts/private/AttackLayout';
import useApi from '../../hooks/useApi';
import useTranslate from '../../hooks/useTranslate';

import { appOptionsState } from '../../data/app';

function AttackCreate({ attack }) {
    const t = useTranslate('attack');

    const options = useRecoilValue(appOptionsState)

    const navigate = useNavigate();

    const [occurredAt, setOccurredAt] = useState(Number(attack?.occurred_at) > 0 ? attack.occurred_at : Math.floor(Date.now() / 1000));
    const [improvedAt, setImprovedAt] = useState(Number(attack?.improved_at) > 0 ? attack.improved_at : null);
    const [resolvedAt, setResolvedAt] = useState(Number(attack?.resolved_at) > 0 ? attack.resolved_at : null);
    const [impact, setImpact] = useState(null)

    const api = useApi()

    const handleSubmit = async () => {

        let areaData = []
        for (let index = 0; index < selectedAreas.length; index++) {
            const element = selectedAreas[index];

            areaData.push({
                id: element,
                note: selectedAreaNotes[element] ? selectedAreaNotes[element] : null
            })
        }

        let symptomData = []
        for (let index = 0; index < selectedSymptoms.length; index++) {
            const element = selectedSymptoms[index];

            symptomData.push({
                id: element,
                note: selectedSymptomNotes[element] ? selectedSymptomNotes[element] : null
            })
        }

        let triggerData = []
        for (let index = 0; index < selectedTriggers.length; index++) {
            const element = selectedTriggers[index];

            triggerData.push({
                id: element,
                note: selectedTriggerNotes[element] ? selectedTriggerNotes[element] : null
            })
        }

        const data = {
            occurred_at: occurredAt,
            improved_at: improvedAt ?? 0,
            resolved_at: resolvedAt ?? 0,

            areas: areaData,
            symptoms: symptomData,
            triggers: triggerData,

            affect: impact.affect,
            affect_timeloss_work: impact.affect_timeloss_work,
            affect_timeloss_study: impact.affect_timeloss_study,
            affect_timeloss_sleep: impact.affect_timeloss_sleep,
            affect_timeloss_other: impact.affect_timeloss_other,

            caregiver_affect: impact.caregiver_affect,
            caregiver_affect_timeloss_work: impact.caregiver_affect_timeloss_work,
            caregiver_affect_timeloss_study: impact.caregiver_affect_timeloss_study,
            caregiver_affect_timeloss_sleep: impact.caregiver_affect_timeloss_sleep,
            caregiver_affect_timeloss_other: impact.caregiver_affect_timeloss_other,
        }

        // ...
        if (attack === undefined) {
            await api.attackCreate(data).then(async (newAttack) => {
                await api.attackUpdate(newAttack, data).then(updatedAttack => {
                    navigate(`/attacks/${updatedAttack.id}`)
                })
            })
        } else {
            await api.attackUpdate(attack, data).then(updatedAttack => {
                navigate(`/attacks/${updatedAttack.id}`)
            })
        }
    }

    const getSelectedItemsAndNotes = (attribute) => {
        // ...
        let notes = {}
        let items = [];
        if (attack === undefined) {
            return [items, notes]
        }

        if (['areas', 'symptoms', 'triggers'].indexOf(attribute) !== -1) {
            for (let index = 0; index < attack[attribute].length; index++) {
                const element = attack[attribute][index];
                notes[element.id] = element.note;
            }

            items = attack[attribute].map(item => String(item.id));
        }

        return [items, notes]
    }

    // ...
    const [defaultSelectedAreas, defaultSelectedAreaNotes] = getSelectedItemsAndNotes('areas')
    const [selectedAreas, setSelectedAreas] = useState(defaultSelectedAreas)
    const [selectedAreaNotes, setSelectedAreaNotes] = useState(defaultSelectedAreaNotes)
    const handleAreaChange = (value) => {
        if (value.items !== undefined) {
            setSelectedAreas(value.items)
        }

        if (value.notes !== undefined) {
            setSelectedAreaNotes(value.notes)
        }
    }

    // ...
    const [defaultSelectedSymptoms, defaultSelectedSymptomNotes] = getSelectedItemsAndNotes('symptoms')
    const [selectedSymptoms, setSelectedSymptoms] = useState(defaultSelectedSymptoms);
    const [selectedSymptomNotes, setSelectedSymptomNotes] = useState(defaultSelectedSymptomNotes);
    const handleSymptomChange = (value) => {
        if (value.items !== undefined) {
            setSelectedSymptoms(value.items)
        }

        if (value.notes !== undefined) {
            setSelectedSymptomNotes(value.notes)
        }
    }

    // ...
    const [defaultSelectedTriggers, defaultSelectedTriggerNotes] = getSelectedItemsAndNotes('triggers')
    const [selectedTriggers, setSelectedTriggers] = useState(defaultSelectedTriggers);
    const [selectedTriggerNotes, setSelectedTriggerNotes] = useState(defaultSelectedTriggerNotes);
    const handleTriggerChange = (value) => {
        if (value.items !== undefined) {
            setSelectedTriggers(value.items)
        }

        if (value.notes !== undefined) {
            setSelectedTriggerNotes(value.notes)
        }
    }

    return (
        <AttackLayout>
            <StepForm onSubmit={handleSubmit}>
                <List>
                    <ListSubheader disableSticky>{t('createHeadlineDateAndTime')}</ListSubheader>
                    <ListItem>
                        <DateTimePicker value={occurredAt} onChange={setOccurredAt} />
                    </ListItem>
                    <ListSubheader disableSticky>{t('updateAttackImproveHeader')}</ListSubheader>
                    <ListItem>
                        <DateTimePicker value={improvedAt} onChange={setImprovedAt} />
                    </ListItem>
                    <ListSubheader disableSticky>{t('updateAttackResolveHeader')}</ListSubheader>
                    <ListItem>
                        <DateTimePicker value={resolvedAt} onChange={setResolvedAt} />
                    </ListItem>
                </List>
                <CheckboxList title={t('createHeadlineAreasAffected')} items={options.attack.areas} selectedItems={selectedAreas} selectedItemNotes={selectedAreaNotes} onChange={handleAreaChange} />
                <CheckboxList title={t('createHeadlineSymptoms')} items={options.attack.symptoms} selectedItems={selectedSymptoms} selectedItemNotes={selectedSymptomNotes} onChange={handleSymptomChange} />
                <CheckboxList title={t('createHeadlineTriggers')} items={options.attack.triggers} selectedItems={selectedTriggers} selectedItemNotes={selectedTriggerNotes} onChange={handleTriggerChange} />
                <AttackImpact onChange={setImpact} attack={attack} />
            </StepForm>
        </AttackLayout>
    )
}

export default AttackCreate;
