import React from 'react';
// import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
// import AttackIcon from '../../icons/attacks.svg';
// import { ReactComponent as AttackIcon } from '../../icons/attacks.svg';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


import { Typography, AppBar, Toolbar } from '@material-ui/core';


// import moment from 'moment';
// import 'moment/locale/da';
// import 'moment/locale/en-gb';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';

import { useLocation, useNavigate, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { attackState } from '../data/attack';
import { useTheme } from '@material-ui/styles';
import useTranslate from '../hooks/useTranslate';
import useSafeArea from '../hooks/useSafeArea';

export default function AppBarAttack() {

    const t = useTranslate('layout')
    const location = useLocation()
    const theme = useTheme()

    let { attack_id } = useParams()

    const attack = useRecoilValue(attackState(attack_id))
    const { sat } = useSafeArea()

    const navigate = useNavigate()

    const handleNavigate = () => {
        let path = -1;
        if (location.pathname === `/attacks/${attack.id}`) {
            path = `/attacks`
        } else if (location.pathname === `/attacks/${attack.id}/treatments`) {
            path = `/attacks/${attack.id}`
        }

        navigate(path)
    }

    return (
        <AppBar id='topAppBar' position="fixed" style={{ paddingTop: sat }}>
            <Toolbar>
                {attack ?
                    <IconButton onClick={handleNavigate} edge="start" color="inherit">
                        <KeyboardArrowLeftIcon style={{ backgroundColor: '#FFFFFF', color: theme.palette.primary.main, borderRadius: 4 }} />
                    </IconButton>
                :
                    <div style={{ width: 48 }} />
                }
                <div style={{ flexGrow: 1 }} />
                {attack ?
                    <Typography variant="h6" noWrap>{moment.unix(attack.occurred_at).format('ll')}</Typography>
                    :
                    <Typography variant="h6" noWrap>{t('indexMenuTabAttack')}</Typography>
                }
                <div style={{ flexGrow: 1 }} />
                <div style={{ width: 48 }} />
            </Toolbar>
        </AppBar>
    );
}
