import { atom, selector, selectorFamily, useSetRecoilState } from "recoil";
import { appDataVersionState, apiUrlState, apiTokenState, appLanguageState, appState } from "./app";
import { findTreatmentInList } from '../helpers/recoil';
import fetchJSON from "../helpers/fetchJSON";

/**
 * 
 */
export const attacksVersionState = atom({
    key: "attacksVersionState",
    default: Date.now(),
});

/**
 * 
 * @returns 
 */
export function useRefreshAttacksVersion() {
    const setState = useSetRecoilState(attacksVersionState)
    return () => {
        setState(Date.now())
    };
}

/**
 * 
 */
export const attacksState_orig = selector({
    key: 'attacksState_orig',
    get: async ({ get }) => {
        get(attacksVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: attacksState_orig')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=attack/index`)
    }
})

/**
 * 
 */
export const attacksState = atom({
    key: "attacksState",
    default: attacksState_orig,
});

/**
 * 
 */
export const attackState = selectorFamily({
    key: 'attackState',
    get: (attackId) => async ({ get }) => {
        get(appDataVersionState)
        get(attacksVersionState)
        if (attackId === undefined) {
            return undefined;
        }

        const attacks = get(attacksState)
        for (let index = 0; index < attacks.length; index++) {
            const attack = attacks[index];
            if (attack.id === Number(attackId)) {
                return attack
            }
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=attack/view&id=${attackId}`)
    },
});

/**
 * 
 */
export const attackTreatmentState = selectorFamily({
    key: 'attackTreatmentState',
    get: ({ attack_id, treatment_id }) => async ({ get }) => {
        get(appDataVersionState)
        get(attacksVersionState)
        const attackList = get(attacksState)
        return findTreatmentInList(attackList, attack_id, treatment_id)
    },
});
