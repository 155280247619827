export function findTreatmentInList(list, parent_id, treatment_id) {
    if (parent_id === undefined || treatment_id === undefined) {
        return null;
    }

    const _list = list.filter(item => Number(item.id) === Number(parent_id))
    if (_list.length === 0) {
        return null;
    }

    const item = _list[0]
    if (!Array.isArray(item.treatments)) {
        return null;
    }


    const _treatmentList = item.treatments.filter(treatment => Number(treatment.id) === Number(treatment_id))
    if (_treatmentList.length > 0) {
        return _treatmentList[0];
    }

    return null
}
