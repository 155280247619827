import React from 'react';
import { Box, TextField, FormHelperText } from '@material-ui/core';

function InputFieldText({ name, type, variant, label, value, hint, autoFocus, autoComplete, onChange }) {
    const handleOnChange = (value) => {
        if (typeof onChange === 'function') {
            onChange(value)
        }
    }

    return (
        <Box style={{ width: '100%' }}>
            <label htmlFor={name} style={{ textAlign: 'left', display: 'block' }}>{label}</label>
            {hint &&
                <FormHelperText>{hint}</FormHelperText>
            }
            <TextField
                type={type ?? 'text'}
                variant={variant ?? 'outlined'}
                id={name}
                name={name}
                margin="normal"
                fullWidth
                inputProps={{ style: { backgroundColor: '#F8F8FA' } }}
                style={{ marginTop: 8, marginBottom: 20 }}
                autoComplete={autoComplete ? name : 'one-time-code'}
                // label={t('signInEmailLabel')}
                value={value}
                autoFocus={autoFocus}
                onChange={e => handleOnChange(e.target.value)}
            />
        </Box>
    )
}

export default InputFieldText;
