import React from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';

import { prophylacticState, prophylacticTreatmentState } from '../../data/prophylactic';
import ProphylacticTreatmentsCreate from './ProphylacticTreatmentsCreate';

function ProphylacticTreatmentsUpdate() {
    const { prophylactic_id, treatment_id } = useParams();

    const prophylactic = useRecoilValue(prophylacticState(prophylactic_id))
    const treatment = useRecoilValue(prophylacticTreatmentState({ prophylactic_id, treatment_id }))

    if (prophylactic === undefined) {
        return null;
    }

    return (
        <ProphylacticTreatmentsCreate prophylactic={prophylactic} treatment={treatment} />
    )
}

export default ProphylacticTreatmentsUpdate;
