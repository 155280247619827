import React, { useState } from 'react';
// import AddIcon from '@material-ui/icons/Add';
// import { Button, Card, CardContent, Container } from '@material-ui/core';
// import moment from 'moment';
// import { ReactComponent as AttackIcon } from '../icons/attacks.svg';

import AttackLayout from '../../layouts/private/AttackLayout'

// import { useTranslate } from '../helpers/i18n';
// import { useRecoilValue } from 'recoil';
// import { attackState } from '../data/selector';
// import { useParams } from 'react-router';


// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../../hooks/useTranslate';

import 'format-unicorn';

import { List, ListSubheader, ListItem } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import mktime from '../../helpers/mktime';
// import FormDialog from '../components/FormDialog';
import DatePicker from '../../components/DatePicker';
import TimePicker from '../../components/TimePicker';
import TherapyField from '../../components/TherapyField';
// import moment from 'moment';
import StepForm from '../../components/StepForm';
import { useRecoilValue } from 'recoil';
import { attackState } from '../../data/attack';
import { userState } from '../../data/user';
import { useNavigate, useParams } from 'react-router';
import CheckboxList from '../../components/CheckboxList';
import useApi from '../../hooks/useApi';

import mapSideEffectsData from "../../helpers/mapSideEffectsData";
import { appOptionsState } from '../../data/app';


function AttackTreatmentsCreate({ treatment }) {

    const t = useTranslate('treatment');

    const api = useApi()

    let { attack_id } = useParams();
    const attack = useRecoilValue(attackState(attack_id))

    const navigate = useNavigate();

    // ...
    const [selectedDate, setSelectedDate] = useState(treatment !== undefined ? treatment.treated_at : Date.now() / 1000);
    const [selectedTime, setSelectedTime] = useState(treatment !== undefined ? treatment.treated_at : Date.now() / 1000);
    const [location, setLocation] = useState(treatment !== undefined ? treatment.location_id : '');
    const options = useRecoilValue(appOptionsState)
    // const { options } = useSelector(state => state.app)
    const { language_datetime } = useRecoilValue(userState)


    const [therapy, setTherapy] = useState(treatment ? { therapy_id: treatment.therapy_id, units: treatment.units } : null);

    const [selectedEffect, setSelectedEffect] = useState(treatment ? treatment.effect : 0);


    const getSelectedItemsAndNotes = () => {
        // ...
        let notes = {}
        let items = [];

        if (treatment !== undefined && Array.isArray(treatment.sideEffects)) {
            for (let index = 0; index < treatment.sideEffects.length; index++) {
                const element = treatment.sideEffects[index];
                notes[element.id] = element.note;
            }

            items = treatment.sideEffects.map(item => String(item.id));
        }

        return [items, notes]
    }

    // ...
    const [defaultSelectedSideEffects, defaultSelectedSideEffectNotes] = getSelectedItemsAndNotes('areas')
    const [selectedSideEffects, setSelectedSideEffects] = useState(defaultSelectedSideEffects)
    const [selectedSideEffectNotes, setSelectedSideEffectNotes] = useState(defaultSelectedSideEffectNotes)
    const handleSideEffectChange = (value) => {
        if (value.items !== undefined) {
            setSelectedSideEffects(value.items)
        }

        if (value.notes !== undefined) {
            setSelectedSideEffectNotes(value.notes)
        }
    }

    // // // ...
    // // let notes = {}
    // // let items = [];

    // // for (let index = 0; index < treatment.sideEffects.length; index++) {
    // //     const sideEffect = treatment.sideEffects[index];
    // //     notes[sideEffect.id] = sideEffect.note;
    // // }

    // // items = treatment.sideEffects.map(sideEffect => String(sideEffect.id));

    // // ...
    // const [selectedSideEffects, setSelectedSideEffects] = useState([]);
    // const [selectedSideEffectNotes, setSelectedSideEffectNotes] = useState({});
    // const handleSideEffectChange = (value) => {
    //     if (value.items !== undefined) {
    //         setSelectedSideEffects(value.items)
    //     }

    //     if (value.notes !== undefined) {
    //         setSelectedSideEffectNotes(value.notes)
    //     }
    // }


    // ...
    const handleSubmit = () => {
        const data = {
            attack_id: attack.id,
            treated_at: mktime(language_datetime, selectedDate, selectedTime),
            location_id: location,
            effect: Number(selectedEffect),
            side_effects: mapSideEffectsData(selectedEffect, selectedSideEffects, selectedSideEffectNotes),
            therapy
        }

        if (treatment) {
            api.updateAttackTreatment(treatment, data).then(() => {
                navigate(`/attacks/${attack.id}/treatments`);
            })
        } else {
            api.createAttackTreatment(data).then(() => {
                navigate(`/attacks/${attack.id}/treatments`);
            })
        }
    }

    const handleValidate = (activeStep) => {
        if (activeStep === 0) {
            return Number(location) > 0;
        } else if (activeStep === 1) {
            return (therapy !== null && Number(therapy.therapy_id) > 0);
        }

        return true;
    }

    if (attack === undefined) {
        return null;
    }

    return (
        <AttackLayout>
            <StepForm onSubmit={handleSubmit} onValidate={handleValidate}>
                <List>
                    <ListSubheader disableSticky>{t('createHeadlineDateAndTime')}</ListSubheader>
                    <ListItem>
                        <DatePicker value={selectedDate} onChange={setSelectedDate} />
                    </ListItem>
                    <ListItem>
                        <TimePicker value={selectedTime} onChange={setSelectedTime} />
                    </ListItem>
                    <ListSubheader disableSticky>{t('createLocationHeader')}</ListSubheader>
                    <ListItem>
                        <FormControl required fullWidth>
                            <InputLabel>{t('createLocationLabel')}</InputLabel>
                            <Select value={location} onChange={e => setLocation(e.target.value)} required>
                                {Object.entries(options.treatment.locations).map(item => {
                                    return <MenuItem style={{ whiteSpace: "normal" }} key={item[0]} value={item[0]}>{item[1].name}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{t('createLocationHint')}</FormHelperText>
                        </FormControl>
                    </ListItem>
                </List>
                <TherapyField item={therapy} type="acute" onChange={setTherapy} />
                <>
                    <List>
                        <ListSubheader disableSticky>{t('editImpactEffectHeadline')}</ListSubheader>
                        <ListItem>
                            <FormControl fullWidth>
                                <InputLabel shrink id="demo-mutiple-checkbox-label">{t('editImpactEffectLabel')}</InputLabel>
                                <Select
                                    value={selectedEffect}
                                    onChange={e => setSelectedEffect(e.target.value)}
                                >
                                    {Object.entries(options.treatment.effects).map(item => {
                                        return <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{t('editImpactEffectHint')}</FormHelperText>
                            </FormControl>
                        </ListItem>
                    </List>
                    {selectedEffect > 0 && 
                        <CheckboxList title={t('editImpactSideEffectsHeadline')} items={options.treatment.sideEffects} selectedItems={selectedSideEffects} selectedItemNotes={selectedSideEffectNotes} onChange={handleSideEffectChange} />
                    }
                </>
            </StepForm>
        </AttackLayout>
    )
}

export default AttackTreatmentsCreate;
