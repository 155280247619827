import React from 'react';
import { useLocation } from "react-router-dom";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

function ThemePrivate({ direction, children }) {
    const location = useLocation();

    const base = {
        direction,
        overrides: {
            MuiListSubheader: {
                root: {
                    lineHeight: 1.5,
                    marginTop: 10,
                    marginBottom: 15,
                    fontSize: 16,
                    fontWeight: 'bold',
                }
            },
            MuiList: {
                padding: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        typography: {
            body1: { fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif' },
            body2: { fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif' },
            button: { fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif' },
            caption: { fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif' },
            fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
            h1: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
            h2: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
            h3: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
            h4: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
            h5: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
            h6: { fontFamily: '"Numans", "Roboto", "Helvetica", "Arial", sans-serif' },
        }
    };

    let theme = null;
    if (location.pathname.startsWith('/attacks')) {
        theme = createTheme({
            ...base,
            palette: {
                primary: { main: '#e64446' },
                secondary: { main: '#888888' }
            }
        });
    } else if (location.pathname.startsWith('/prophylactic')) {
        theme = createTheme({
            ...base,
            palette: {
                primary: { main: '#eaa824', contrastText: '#ffffff' },
                secondary: { main: '#e64446' }
            }
        });
    } else if (location.pathname.startsWith('/home')) {
        theme = createTheme({
            ...base,
            palette: {
                primary: { main: '#62A6D8' },
                secondary: { main: '#e64446' }
            }
        });
    } else {
        theme = createTheme({
            ...base,
            palette: {
                primary: { main: '#005d9c' },
                secondary: { main: '#e64446' }
            }
        })
    }

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default ThemePrivate;
