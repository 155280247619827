import { useRecoilState, useRecoilValue } from "recoil";
import { apiTokenState, appLanguageState } from "../data/app";
import { apiUrlState } from "../data/app";

function useApiFetch() {
    const [apiToken, setApiToken] = useRecoilState(apiTokenState)
    const apiUrl = useRecoilValue(apiUrlState)
    const appLanguage = useRecoilValue(appLanguageState)

    /**
     * 
     * @param {*} url 
     * @returns 
     */
    function getHeaders(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const isLoggedIn = !!apiToken;
        const isApiUrl = url.startsWith(apiUrl);
        if (isLoggedIn && isApiUrl) {
            return { 
                'Authorization': `Bearer ${apiToken}`, 
                'Accept-Language': `${appLanguage}`
            };
        } else {
            return {
                'Accept-Language': `${appLanguage}`
            };
        }
    }

    /**
     * 
     * @param {*} method 
     * @returns 
     */
    function request(method) {
        return async (url, body) => {
            const requestOptions = {
                method,
                headers: getHeaders(url)
            };
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }
            const response = await fetch(url, requestOptions);
            return handleResponse(response);
        }
    }

    /**
     * 
     * @param {*} response 
     * @returns 
     */
    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status) && apiToken) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('authToken');
                    setApiToken(null);
                    // history.push('/login');
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        });
    } 

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    }
}

export default useApiFetch;
