import React from 'react';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';
import moment from 'moment';

import { FormControl, FormHelperText } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { userState } from '../data/user';

export default function TimePicker({ momentFormat, onChange, value, minDate, maxDate }) {
    const t = useTranslate('component');
    const { language_datetime } = useRecoilValue(userState);

    const handleChange = (date) => {
        if (momentFormat !== undefined) {
            date = momentFormat(date);
        }

        if (date !== null) {
            onChange(date.unix())
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateMomentUtils} locale={language_datetime}>
            <FormControl fullWidth>
                <KeyboardTimePicker
                    // variant="static"
                    id="date-picker-dialog"
                    label={t('timePickerLabel')}
                    ampm={moment(new Date()).locale(language_datetime).format('LT').toLocaleUpperCase().search(/AM|PM/) !== -1}
                    value={value ? moment.unix(value) : null}
                    InputLabelProps={{ shrink: true }}
                    minDate={(minDate !== undefined && minDate > 0) ? moment.unix(minDate) : new Date(((new Date()).getFullYear() - 1) +'-01-01')}
                    maxDate={(maxDate !== undefined && maxDate > 0) ? moment.unix(maxDate) : new Date()}
                    onChange={handleChange}
                    cancelLabel={t('timePickerLabelCancel')}
                    okLabel={t('timePickerLabelOK')}
                />
                <FormHelperText>{t('timePickerHint')}</FormHelperText>
            </FormControl>
        </MuiPickersUtilsProvider>
    )
}
