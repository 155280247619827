import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Box } from '@material-ui/core';

import BottomNavigationMain from '../../components/BottomNavigationMain';
import AppBarMore from '../../components/AppBarMore';
import LoadingBox from '../../components/LoadingBox';
import getContentMargins from '../../helpers/getContentMargins';
import useSafeArea from '../../hooks/useSafeArea';

function MoreLayout({ children }) {
    const [marginTop, marginBottom] = getContentMargins()
    const { calcSat, calcSab } = useSafeArea()

    return (
        <>
            <AppBarMore />
            <Box className='fullHeight' style={{ overflow: 'auto' }}>
                <div style={{ height: calcSat(marginTop) }}></div>
                <Suspense fallback={<LoadingBox />}>
                    {children === undefined ? <Outlet /> : children}
                </Suspense>
                <div style={{ height: calcSab(marginBottom) }}></div>
            </Box>
            <BottomNavigationMain />
        </>
    )
}

export default MoreLayout;
