import React, { useState } from 'react';

import MoreLayout from '../../layouts/private/MoreLayout';
import { List, ListItem, ListItemIcon, ListItemText, Box, useTheme } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ReplayIcon from '@material-ui/icons/Replay';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiTokenState, appState, useRefreshAppData } from '../../data/app';
import { userLastLoginState, userState } from '../../data/user';
import ConfirmDialog from '../../components/ConfirmDialog';
import useTranslate from '../../hooks/useTranslate';
import useApi from '../../hooks/useApi';
import AlertBox from '../../components/AlertBox';

function MoreIndex() {
    const t = useTranslate('more');
    const navigate = useNavigate()
    const { state, version, build } = useRecoilValue(appState)
    const { first_name, last_name } = useRecoilValue(userState)
    const setApiToken = useSetRecoilState(apiTokenState)
    const setUserLastLogin = useSetRecoilState(userLastLoginState)
    const theme = useTheme()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const api = useApi()

    const refreshAppData = useRefreshAppData()

    const handleLogout = () => {
        setApiToken(null)
        localStorage.removeItem('token')
        setUserLastLogin(0)
        localStorage.removeItem('last_login')
        navigate(`/`)
    }

    const handleConfirmDelete = (force) => {
        api.userDelete({ force }).then(() => {
            handleLogout()
        })
    }

    return (
        <MoreLayout>
            <AlertBox />
            <Box textAlign='center' justifyContent='center' display='flex' alignItems='center' style={{ marginTop: 30, color: theme.palette.text.secondary, fontSize: '1.3em' }}>
                <PersonIcon fontSize="large" color='primary'  />&nbsp;&nbsp;{[first_name, last_name].join(' ')}
            </Box>
            <List style={{ marginTop: 50 }}>
                <ListItem onClick={() => navigate('/user/reports')} button divider>
                    <ListItemIcon>
                        <BarChartIcon fontSize="large" color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={t('indexMenuItemReports')} primaryTypographyProps={{ style: { fontSize: '1.5em' } }} />
                </ListItem>
                <ListItem onClick={() => navigate('/user/account')} button divider>
                    <ListItemIcon>
                        <PersonOutlineIcon fontSize="large" color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={t('indexMenuItemMyAccount')} primaryTypographyProps={{ style: { fontSize: '1.5em' } }} />
                </ListItem>
                <ListItem onClick={refreshAppData} button divider>
                    <ListItemIcon>
                        <ReplayIcon fontSize="large" color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={t('indexMenuReloadData')} primaryTypographyProps={{ style: { fontSize: '1.5em' } }} />
                </ListItem>
                <ListItem onClick={handleLogout} button divider>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="large" color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={t('indexMenuItemSignOut')} primaryTypographyProps={{ style: { fontSize: '1.5em' } }} />
                </ListItem>
                <ListItem onClick={() => setConfirmDelete(!confirmDelete)} style={{ marginTop: 50 }} button divider>
                    <ListItemIcon>
                        <DeleteIcon fontSize="large" color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary={t('indexMenuItemDeleteAccount')} primaryTypographyProps={{ style: { fontSize: '1.5em' } }} />
                </ListItem>
            </List>
            <Box textAlign='center' style={{ marginTop: 20, color: theme.palette.text.secondary, fontSize: '0.75em' }}>
                HAE TrackR - v{version}<br />
                Build: {build}
                {state !== 'prod' &&
                    <p style={{ width: '100%', marginTop: 0, color: 'red', fontWeight: 600 }}>
                        DEVELOPMENT VERSION
                    </p>
                }
                <div style={{ height: 10 }}></div>
            </Box>
            {confirmDelete && 
                <ConfirmDialog open={true} checkbox={t('deleteAccountDialogCheckbox')} close={() => { setConfirmDelete(false) }} confirm={handleConfirmDelete} title={t('deleteAccountDialogHeader')} text={t('deleteAccountDialogText')} />
            }
        </MoreLayout>
    )
}

export default MoreIndex;
