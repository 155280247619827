import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControlLabel, Checkbox } from '@material-ui/core';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';
import { useState } from 'react';


export default function ConfirmDialog(props) {
    const t = useTranslate('component');

    const [check, setCheck] = useState(false)

    const handleClose = (e) => {
        e.stopPropagation();
        if (typeof props.close == 'function') {
            props.close(e)
        }
    }

    const handleConfirm = (e) => {
        e.stopPropagation();
        if (typeof props.confirm == 'function') {
            props.confirm(check)
        }
    }

    return (
        <Dialog open={props.open} onClose={props.close} >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.text}</DialogContentText>
                {props.checkbox !== undefined &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={check}
                                onChange={() => setCheck(!check)}
                                color="primary"
                            />
                        }
                        label={props.checkbox}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">{t('confirmDialogButtonNo')}</Button>
                <Button onClick={handleConfirm} color="primary">{t('confirmDialogButtonYes')}</Button>
            </DialogActions>
        </Dialog>
    )
}
