import { atom, selector } from "recoil";
import { apiUrlState, apiTokenState, appDataVersionState, appLanguageState, appState } from "./app";
import fetchJSON from "../helpers/fetchJSON";

/**
 * 
 */
export const userState = selector({
    key: 'userState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: userState')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/auth/?r=user`)
    }
})

/**
 * 
 */
export const userOptionsState = selector({
    key: 'userOptionsState',
    get: async ({ get }) => {
        get(appDataVersionState)
        if (get(appState).state !== 'prod') {
            console.debug('data: userOptionsState')
        }

        return await fetchJSON({
            token: get(apiTokenState),
            language: get(appLanguageState)
        }).get(`${get(apiUrlState)}/api/?r=app/user-options`)
    }
})

/**
 * 
 */
export const userLastLoginState = atom({
    key: "userLastLoginState",
    default: localStorage.getItem('last_login') ?? 0,
});
