import { Box, CircularProgress, useTheme } from '@material-ui/core';

function LoadingBox({ height }) {
    const theme = useTheme()
    return (
        <Box display='flex' alignItems='center' flexDirection='column' justifyContent='center' height={height ?? '100vh'}>
            <CircularProgress style={{ color: theme.palette.grey[400] }} size={80} />
        </Box>
    )
}

export default LoadingBox;
