import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextField, Button } from '@material-ui/core';

import { appState } from '../../data/app';
import useApi from '../../hooks/useApi';
import useTranslate from '../../hooks/useTranslate';
import LanguageSwitch from '../../components/LanguageSwitch';
import useSafeArea from '../../hooks/useSafeArea';
// import { PushNotifications } from '@capacitor/push-notifications';
// import { LocalNotifications } from '@capacitor/local-notifications';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: 40,
    },
    subHeader: {
        fontSize: '1em'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textCenter: {
        textAlign: 'center',
        marginTop: 20,
        fontSize: 10
    }
}));

function InputField({ name, type, variant, label, value, onChange }) {
    const handleOnChange = (value) => {
        if (typeof onChange === 'function') {
            onChange(value)
        }
    }

    return (
        <Box style={{ width: '100%' }}>
            <label htmlFor={name} style={{ textAlign: 'left', display: 'block' }}>{label}</label>
            <TextField
                type={type ?? 'text'}
                variant={variant ?? 'outlined'}
                id={name}
                name={name}
                margin="normal"
                fullWidth
                inputProps={{ style: { backgroundColor: '#F8F8FA' } }}
                style={{ marginTop: 8, marginBottom: 20 }}
                autoComplete={name}
                // label={t('signInEmailLabel')}
                defaultValue={value}
                autoFocus
                onChange={e => handleOnChange(e.target.value)}
            />
        </Box>
    )
}

export default function UserSignIn() {
    const classes = useStyles();
    const t = useTranslate('user');
    const api = useApi()
    const navigate = useNavigate()

    const { state, version, build } = useRecoilValue(appState)

    const [error, setError] = useState(null);
    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const { calcSat } = useSafeArea()

    const validate = () => {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(inputEmail) && inputPassword.length >= 8) {
            return true;
        }
        return false;
    }

    const handleLogin = (e) => {
        e.preventDefault();

        localStorage.setItem('version', version);
        setError(null);

        // ...
        if (!validate()) {
            return false;
        }

        api.userLogin(inputEmail, inputPassword).catch(() => {
            setError('Failed to login, maybe you entered an incorrect username or password?');
        });
    }


    // useEffect(() => {
    //     // Request permission to use push notifications
    //     // iOS will prompt user and return if they granted permission or not
    //     // Android will just grant without prompting
    //     PushNotifications.requestPermissions().then(result => {
    //         if (result.receive === 'granted') {
    //             // Register with Apple / Google to receive push via APNS/FCM
    //             PushNotifications.register();
    //         } else {
    //             // Show some error
    //         }
    //     });

    //     // On success, we should be able to receive notifications
    //     PushNotifications.addListener('registration',
    //         (token) => {
    //             console.log('Push registration success, token: ' + token.value);
    //         }
    //     );

        

    //     // Some issue with our setup and push will not work
    //     PushNotifications.addListener('registrationError',
    //         (error) => {
    //             console.log('Error on registration: ' + JSON.stringify(error));
    //         }
    //     );

    //     PushNotifications.addListener('pushNotificationReceived', (notification) => {
    //         LocalNotifications.schedule([{
    //             id: Date.now(),
    //             title: notification.title,
    //             body: notification.body,
    //             ongoing: false,
    //         }])

    //         // console.log(t);

    //         // alert('Push received: ' + JSON.stringify(notification));
    //         console.log('Push received:', notification);

    //     });

    //     PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    //         // alert('Push action performed: ' + JSON.stringify(notification));
    //         console.log('Push action performed:', notification);
    //     });
    // }, [])

    // ...
    return (
        <Box maxWidth="xs" style={{ padding: '0 9px', paddingTop: calcSat(10) }}>
            <Grid container>
                <Grid xs={3} item></Grid>
                <Grid style={{ textAlign: 'center', paddingTop: 30 }} xs={6} item>
                    <img alt="App logo" src="/logo192.png" style={{ width: 140, height: 'auto' }} />
                </Grid>
                <Grid xs={3} item>
                    <Box style={{ textAlign: 'right' }}>
                        <LanguageSwitch />
                    </Box>
                </Grid>
            </Grid>
            <div className={classes.paper}>
                <Box textAlign='center' style={{ marginTop: 20, width: '90%' }}>
                    {error &&
                        <Alert style={{ marginBottom: 20, marginTop: 10 }} severity="error">{error}</Alert>
                    }
                    <InputField name="username" label={t('signInEmailLabel')} value={inputEmail} onChange={setInputEmail} />
                    <InputField name="password" type="password" label={t('signInPasswordLabel')} value={inputPassword} onChange={setInputPassword} />
                    <Box textAlign='left'>
                        <Link to="/reset-password" variant="body2">{t('linkResetPassword')}</Link>
                    </Box>
                    <Button
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        style={{ borderRadius: 30, marginTop: 30, width: '70%', height: 60, backgroundColor: '#62A6D8', color: '#FFFFFF', fontWeight: 600 }}
                        disabled={!validate()}
                    >{t('signInButton')}</Button>
                    <Box style={{ marginBottom: 30 }} />
                    <Box style={{ borderTop: '1px solid #CCC', paddingTop: 30, width: '20%', margin: '0 auto' }} />
                    <Button onClick={() => navigate(`/signup`)}
                        variant='outlined'
                        style={{ borderRadius: 40, width: '70%', height: 60, borderColor: '#62A6D8', color: '#62A6D8', fontWeight: 600 }}
                    >{t('linkSignUp')}</Button>
                </Box>
                <Grid style={{ marginTop: 20 }} container>
                    <Grid className={classes.textCenter} style={{ width: '100%', marginBottom: 20 }} item>
                        v{version}<br />
                        Build: {build}
                        {state !== 'prod' &&
                            <p style={{ width: '100%', marginTop: 0, color: 'red', fontWeight: 600 }}>
                                DEVELOPMENT VERSION
                            </p>
                        }
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}
