import React, { useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'format-unicorn';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useTranslate from '../../hooks/useTranslate';
import useApi from '../../hooks/useApi';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    subHeader: {
        fontSize: '1em'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function UserVerifyAccount() {
    const classes = useStyles();
    const [inputToken, setInputToken] = useState('')
    const [alert, setAlert] = useState(null)
    const navigate = useNavigate()

    const { email } = useParams()
    const t = useTranslate('user')
    const [searchParams] = useSearchParams()
    const api = useApi()

    const urlEmail = email ?? searchParams.get('email')

    const handleVerify = () => {
        setAlert(null);

        api.userVerifyAccount(urlEmail, inputToken).then(() => {
            navigate(`/`);
        }).catch(error => {
            setAlert({ type: 'error', message: error});
        })
    }

    const handleSendVerification = () => {
        setAlert(null);

        api.userSendVerificationMail(urlEmail).then(() => {
            setAlert({ type: 'success', message: 'We have sent a new verification code to {email}'.formatUnicorn({ email }) });
        }).catch(error => {
            setAlert({ type: 'error', message: error });
        })
    }

    if (!urlEmail) {
        return (<>...</>)
    }

    // ...
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">HAE TrackR</Typography>
                <Typography component="h2" className={classes.subHeader}>{t('verifyAccountHeader')}</Typography>
                <form className={classes.form} noValidate>
                    {alert &&
                        <Alert style={{ marginBottom: 20, marginTop: 10 }} severity={alert.type}>{alert.message}</Alert>
                    }
                    <p style={{ maxWidth: '80%' }}>{t('verifyAccountMessage')}</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                defaultValue={inputToken}
                                onChange={e => setInputToken(e.target.value)}
                                variant="outlined"
                                required
                                fullWidth
                                autoFocus
                                autoComplete="off"
                                label={t('verifyAccountVerificationCodeLabel')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleVerify}
                            >{t('verifyAccountButton')}</Button>
                        </Grid>
                    </Grid>
                    <p style={{marginTop: 30}}>{t('verifyAccountMissingEmailMessage')}</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                margin="normal"
                                variant="contained"
                                style={{ minWidth: '60%' }}
                                onClick={handleSendVerification}
                            >{t('verifyAccountResendButton')}</Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid container className={classes.form} style={{marginTop: 30}}>
                    <Grid item xs>
                        <Link to="/reset-password" variant="body2">{t('linkResetPassword')}</Link>
                    </Grid>
                    <Grid item>
                        <Link to="/signup" variant="body2">{t('linkSignUp')}</Link>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
