import React, { useState, useRef, useCallback } from 'react';

import { List, ListSubheader, ListItem, Grid, TextField, ListItemText } from '@material-ui/core';
import { FormControl, FormHelperText, InputLabel, Button } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
// import { useTranslate } from 'react-translate';
// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


import Cropper from 'react-easy-crop'

import Slider from '@material-ui/core/Slider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRecoilValue } from 'recoil';
import { userState } from '../data/user';
import { appOptionsState } from '../data/app';


function MyFormHelperText(props) {
    return (props.children !== '') ? <FormHelperText>{props.children}</FormHelperText> : '';
}

function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // TODO: downscale to a max size eg 600 x 120


    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    return canvas.toDataURL('image/jpeg');
}

const createImage = (url) => new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
})



function ImageCropModal({ src, onClose, onChange, onSubmit }) {
    const t = useTranslate('component');

    const [open, setOpen] = useState(true)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    const handleOnSubmit = async () => {
        const croppedImage = await getCroppedImg(
            src,
            croppedAreaPixels
        )

        if (typeof onSubmit === 'function') {
            onSubmit(croppedImage)
        }

        handleOnClose()
    }

    const handleOnClose = () => {
        setOpen(!open)
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >{t('batchCropDialogHeader')}</Box>
                    <Box>
                        <IconButton onClick={handleOnClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Box style={{ position: "relative", height: 400 }}>
                <Cropper
                    image={src}
                    crop={crop}
                    // rotation={rotation}
                    zoom={zoom}
                    aspect={600 / 200}
                    onCropChange={setCrop}
                    // onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                />
            </Box>
            <DialogContent>
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                />
            </DialogContent>
            <DialogActions style={{ paddingBottom: 10 }}>
                <Button size="large" variant="contained" onClick={handleOnSubmit} color="primary" fullWidth>
                    {t('batchCropUseSelection')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}



function EditBatch({ batch, onChange, label }) {
    const t = useTranslate('component');
    const [rawImage, setRawImage] = useState(undefined)

    const inputRef = useRef(null)

    const handleChangeBatch = (value) => {
        handleOnChange(value, batch.image)
    }

    const handleChangeImage = (value) => {
        handleOnChange(batch.identity, value)
    }

    const handleOnChange = (identity, image) => {
        if (typeof onChange === 'function') {
            onChange({ identity, image })
        }
    }

    return (
        <ListItem>
            <FormControl fullWidth>
                <Input
                    type="text"
                    value={batch.identity}
                    onChange={e => handleChangeBatch(e.target.value)}
                    // onChange={e => { handleBatchUpdate(item_index, index, e.target.value) }}
                    label={label}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={() => inputRef.current.click()}>
                                <PhotoCameraIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <MyFormHelperText>{t('therapyFieldBatchNoHint')}</MyFormHelperText>
                <input style={{ display: 'none' }} accept="image/*" type="file" ref={inputRef} name="myImage" onChange={(e) => setRawImage(e.target.files[0])} />
                {batch.image && <img style={{ marginTop: 10 }} alt="not fount" width={"100%"} src={batch.image} />}
                {/* {rawImage && <ImageCropModal src={URL.createObjectURL(rawImage)} onClose={() => setImage(null)} onSubmit={setCropped} />} */}
                {rawImage && <ImageCropModal
                    src={URL.createObjectURL(rawImage)}
                    onClose={() => setRawImage(undefined)}
                    onSubmit={handleChangeImage}
                />}
            </FormControl>
        </ListItem >
    )
}


export default function TherapyField(props) {
    // ...
    const t = useTranslate('component');
    const options = useRecoilValue(appOptionsState)
    const { clinical_trials, preferred_therapies } = useRecoilValue(userState);

    // ...
    const [therapy, setTherapy] = useState(props.item ? props.item.therapy_id : '');
    const [units, setUnits] = useState((props.item && props.item.units.length > 0) ? props.item.units : []);

    // ...
    const handleTherapyChange = (value) => {
        setTherapy(value)

        // const _units = listDose(options.treatment.therapies[value].units, '0');
        // if (_units.length > 0) {
        const _units = [createNewUnit(value)];
        setUnits(_units)
        handleOnChange(value, _units)
    }

    // this sux due to frozen object/arrays
    const handleBatchUpdate = (item_index, index, value) => {
        // copy units
        let currentUnits = [...units]
        // copy unit
        let currentUnit = {...currentUnits[item_index]}
        // copy unit batches
        let currentBatches = [...currentUnit.batches]
        // change unit batch
        currentBatches[index] = value
        // change unit batchs
        currentUnit.batches = currentBatches
        // change unit
        currentUnits[item_index] = currentUnit
        // ...
        setUnits(currentUnits)
        handleOnChange(therapy, currentUnits)
    }

    const createNewUnit = (therapy_id) => {
        const _units = listDose(options.treatment.therapies[therapy_id].units, '0');
        if (_units.length > 0) {
            return {
                unit_id: _units[0][0],
                amount: '1',
                note: '',
                batches: []
            }
        } else {
            return {
                unit_id: 0,
                amount: '1',
                note: '',
                batches: []
            }
        }
    }

    // ...
    const handleAddUnit = () => {
        // const remaining = listDose(options.treatment.therapies[therapy].units, '0');
        // if (remaining.length > 0) {
        let currentUnits = [...units];
        // _newUnit.unit_id = remaining[0][0];
        currentUnits.push(createNewUnit(therapy));
        setUnits(currentUnits);
        handleOnChange(therapy, currentUnits)
        // }
    }

    // ...
    const listDose = (items, current) => {
        let entries = Object.entries(items);

        let remaining = [];
        for (let i1 = 0; i1 < entries.length; i1++) {
            const e1 = entries[i1];

            let found = false;
            for (let i2 = 0; i2 < units.length; i2++) {
                const e2 = units[i2];
                if (Number(current) !== Number(e1[0]) && Number(e1[0]) === Number(e2.unit_id)) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                remaining.push(e1)
            }
        }

        return remaining;
    }

    const setAttr = (index, attr, value) => {
        let newUnit = {...units[index]}
        newUnit[attr] = value

        return newUnit
    }

    // ...
    const handleUnitAmount = (index, value) => {
        if (/^\d+$/.test(value) || value === '') {
            if (Number(value) > 10) {
                value = '10';
            }

            if (value === '') {
                value = '0';
            }

            let newUnits = [...units];
            newUnits[index] = setAttr(index, 'amount', String(Number(value)))

            setUnits(newUnits);
            handleOnChange(therapy, newUnits)
        }
    }

    // ...
    const handleUnitDose = (index, value) => {

        let newUnits = [...units];
        newUnits[index] = setAttr(index, 'unit_id', value)

        setUnits(newUnits);
        handleOnChange(therapy, newUnits)
    }

    // ...
    const handleUnitNote = (index, value) => {

        let newUnits = [...units];
        newUnits[index] = setAttr(index, 'note', value)

        setUnits(newUnits);
        handleOnChange(therapy, newUnits)
    }

    // ...
    const handleRemoveUnit = (index) => {
        let currentUnits = [...units];
        currentUnits.splice(index, 1);
        setUnits(currentUnits);
        handleOnChange(therapy, currentUnits)
    }

    const handleOnChange = (therapy_id, units) => {
        if (typeof props.onChange === 'function') {
            props.onChange({ therapy_id, units })
        }
    }

    const handleOnChangeBatch = (item_index, index, value) => {
        handleBatchUpdate(item_index, index, value)
    }

    // ...
    const renderBatchFields = (item, item_index) => {
        let rows = [];
        for (let index = 0; index < Number(item.amount); index++) {
            rows.push(
                <EditBatch
                    key={index}
                    label={t('therapyFieldBatchNoLabel').formatUnicorn({ unit: (index + 1) })}
                    onChange={value => handleOnChangeBatch(item_index, index, value)}
                    batch={{
                        identity: (item.batches.length > 0 && item.batches[index] !== undefined) ? item.batches[index].identity : '',
                        image: (item.batches.length > 0 && item.batches[index] !== undefined) ? item.batches[index].image : '',
                    }} />
            )
        }

        return rows
    }

    const findTherapy = (therapy_id) => {
        const therapies = Object.entries(options.treatment.therapies)
        for (let index = 0; index < therapies.length; index++) {
            if (+therapies[index][0] === +therapy_id) {
                return therapies[index][1]
            }
        }
    }

    const disableTherapy = () => {
        if (props.editTherapy !== undefined ? !props.editTherapy : false) {
            return true
        }

        const _therapy = findTherapy(+therapy)
        if (_therapy === undefined) {
            return false
        }

        if (clinical_trials === 0 && _therapy.clinical_trials === 1) {
            return true;
        }

        return false
    }


    const removeTherapy = (_therapy, _therapy_id) => {
        if (clinical_trials === 0 && _therapy.clinical_trials === 1 && !disableTherapy()) {
            return true;
        }

        if (props?.type === 'acute' && preferred_therapies?.length > 0 && preferred_therapies?.indexOf(_therapy_id) === -1) {
            return true;
        }
    }


    return (
        <List>
            <ListSubheader disableSticky>{t('therapyFieldHeadline')}</ListSubheader>
            <ListItem>
                <FormControl required fullWidth>
                    <InputLabel>{t('therapyFieldLabel')}</InputLabel>
                    <Select
                        value={therapy}
                        onChange={e => handleTherapyChange(e.target.value)}
                        disabled={disableTherapy()}
                        required
                    >
                        {Object.entries(options.treatment.therapies).map(item => {
                            if (item[1][props.type] !== undefined && item[1][props.type] === false) {
                                return null;
                            }

                            // let allow = true
                            // if (!!item[1].clinical_trials && item[1].clinical_trials !== clinical_trials) {
                            //     allow = false
                            // }

                            if (removeTherapy(item[1], item[0])) {
                                return null
                            }

                            return (
                                <MenuItem style={{ whiteSpace: "normal" }} key={item[0]} value={item[0]}>
                                    <ListItemText primary={item[1].name} secondary={item[1].description} />
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText>{t('therapyFieldHint')}</FormHelperText>
                </FormControl>
            </ListItem>
            {/* {therapy && options.treatment.therapies[therapy].note === true &&
                <ListItem>
                    <FormControl fullWidth>
                        <TextField label='Note' onChange={e => { setNote(e.target.value) }} InputLabelProps={{ shrink: true }} />
                        <MyFormHelperText>...</MyFormHelperText>
                    </FormControl>
                </ListItem>
            } */}
            {therapy !== '' && units.map((_unit, index) => {
                return <div key={index}>
                    <ListSubheader disableSticky>{t('therapyFieldUnitsHeadline')}</ListSubheader>
                    <ListItem>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl required fullWidth>
                                    <TextField type="number" onChange={e => handleUnitAmount(index, e.target.value)} value={_unit.amount} inputProps={{ value: _unit.amount }} label={t('therapyFieldUnitsLabel')} />
                                    {/* <TextField type="number" onChange={e => handleUnitAmount(index, e.target.value)} defaultValue={_unit.amount} inputProps={{ value: _unit.amount }} label={t('therapyFieldUnitsLabel')} InputLabelProps={{ shrink: true }} /> */}
                                    <MyFormHelperText>{t('therapyFieldUnitsHint')}</MyFormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl required fullWidth>
                                    <InputLabel>{t('therapyFieldUnitDoseLabel')}</InputLabel>
                                    {/* <Select defaultValue={_unit.unit_id} onChange={e => handleUnitDose(index, e.target.value)} required> */}
                                    <Select value={_unit.unit_id} onChange={e => handleUnitDose(index, e.target.value)} required>
                                        {Object.entries(options.treatment.therapies[therapy].units).length > 0 &&
                                            listDose(options.treatment.therapies[therapy].units, _unit.unit_id).map(unit => {
                                                return <MenuItem key={unit[0]} value={unit[0]}>{unit[1]}</MenuItem>
                                            })
                                        }
                                        {Object.entries(options.treatment.therapies[therapy].units).length === 0 &&
                                            <MenuItem value="0">{t('therapyFieldUnitDoseEmpty')}</MenuItem>
                                        }
                                    </Select>
                                    <FormHelperText>{t('therapyFieldUnitDoseHint')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ListItem>
                    {props.showBatch !== false &&
                        renderBatchFields(_unit, index)
                    }
                    {options.treatment.therapies[therapy].note === true &&
                        <ListItem>
                            <FormControl fullWidth>
                                <TextField label={t('therapyFieldNoteLabel')} value={_unit.note} onChange={e => { handleUnitNote(index, e.target.value) }} />
                                {/* <TextField label={t('therapyFieldNoteLabel')} defaultValue={_unit.note} onChange={e => { handleUnitNote(index, e.target.value) }} InputLabelProps={{ shrink: true }} /> */}
                                <MyFormHelperText>{t('therapyFieldNoteHint')}</MyFormHelperText>
                            </FormControl>
                        </ListItem>
                    }
                    {units.length > 1 &&
                        <ListItem>
                            <Button type="button" onClick={e => handleRemoveUnit(index)} variant="contained" fullWidth>{t('therapyFieldButtonRemoveUnit')}</Button>
                        </ListItem>
                    }
                </div>
            })}
            {therapy !== '' && listDose(options.treatment.therapies[therapy].units, '0').length > 0 &&
                <ListItem>
                    <Button type="button" fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleAddUnit}
                    >{t('therapyFieldButtonAddUnit')}</Button>
                </ListItem>
            }
        </List >
    )
}
