import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { List, ListSubheader, ListItem, Button } from '@material-ui/core';

import DatePicker from '../../components/DatePicker';
import TherapyField from '../../components/TherapyField';
import ProphylacticNotifications from '../../components/ProphylacticNotifications';
import ProphylacticLayout from '../../layouts/private/ProphylacticLayout';
import useTranslate from '../../hooks/useTranslate';
import StepForm from '../../components/StepForm';

import useApi from '../../hooks/useApi';

function ProphylacticCreate({ prophylactic }) {
    const t = useTranslate('prophylactic')
    const navigate = useNavigate()
    const api = useApi()

    const [startDate, setStartDate] = useState(prophylactic ? prophylactic.begin_at : moment().unix());
    const [endDate, setEndDate] = useState(prophylactic ? (prophylactic.end_at > 0 ? prophylactic.end_at : moment().unix()) : moment().unix());
    const [enableEndDate, setEnableEndDate] = useState(prophylactic ? (prophylactic.end_at > 0) : false);
    const maxDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24 * 365 * 5);

    const [therapy, setTherapy] = useState({ 
        therapy_id: prophylactic ? prophylactic.therapy_id : '',
        units: prophylactic ? prophylactic.units : [],
        note: '', // maybe not in use?
    });

    const [notification, setNotification] = useState(prophylactic ? {
        atDate: prophylactic.remind_at_date,
        atTime: prophylactic.remind_at_time,
        timestamp: prophylactic.remind_timestamp,
        repeat: prophylactic.remind_repeat,
        custom: prophylactic.remind_custom,
    } : null);

    const handleSubmit = () => {
        const data = {
            begin_at: startDate,
            end_at: enableEndDate ? endDate : 0,
            notification,
            therapy
        }

        if (prophylactic) {
            api.prophylacticUpdate(prophylactic, data).then(() => {
                navigate(`/prophylactics/${prophylactic.id}`)
            })
        } else {
            api.prophylacticCreate(data).then(prophylactic => {
                navigate(`/prophylactics/${prophylactic.id}`)
            })
        }
    }

    const handleValidate = (activeStep) => {
        if (activeStep === 1) {
            return therapy.therapy_id > 0
        }

        return true;
    }

    return (
        <ProphylacticLayout>
            <StepForm onValidate={handleValidate} onSubmit={handleSubmit}>
                <List>
                    <ListSubheader disableSticky>{t('createHeaderStartDate')}</ListSubheader>
                    <ListItem>
                        <DatePicker value={startDate} maxDate={maxDate} onChange={setStartDate} />
                    </ListItem>
                    {enableEndDate &&
                        <>
                            <ListSubheader disableSticky>{t('createHeaderEndDate')}</ListSubheader>
                            <ListItem>
                                <DatePicker value={endDate} minDate={startDate} maxDate={maxDate} onChange={setEndDate} />
                            </ListItem>
                        </>
                    }
                    <ListItem>
                        <Button type="button" fullWidth
                            variant="contained"
                            onClick={() => setEnableEndDate(!enableEndDate)}
                        >{enableEndDate ? t('createRemoveEndDate') : t('createSetEndDate')}</Button>
                    </ListItem>
                </List>
                <TherapyField item={therapy} showBatch={false} type="prophylactic" onChange={setTherapy} />
                <ProphylacticNotifications value={notification} onChange={setNotification} maxDate={maxDate} />
            </StepForm>
        </ProphylacticLayout>
    )
}

export default ProphylacticCreate;
