/**
 * 
 */
export function guessLanguage() {
    // console.debug(navigator.userAgentData);
    let language = navigator.language || navigator.userLanguage;
    if (typeof language === 'string') {
        language = language.toLowerCase();
        const languages = ['da', 'en', 'de'];
        if (languages.indexOf(language) === -1) {
            const languageParts = language.split('-');
            if (languages.indexOf(languageParts[0]) !== -1) {
                return languageParts[0];
            }
        } else {
            return language;
        }
    }

    // fallback to english
    return 'en';
}
