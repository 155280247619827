import React from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';

import { attackState } from '../../data/attack';
import AttackCreate from './AttackCreate';

function AttackUpdate() {
    let { attack_id } = useParams();
    const attack = useRecoilValue(attackState(attack_id))

    if (attack === undefined) {
        return null;
    }

    return (
        <AttackCreate attack={attack} />
    )
}

export default AttackUpdate;
