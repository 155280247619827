function useSafeArea() {
    const sat = Number(window.getComputedStyle(document.documentElement).getPropertyValue("--sat").replace('px', ''));
    const sar = Number(window.getComputedStyle(document.documentElement).getPropertyValue("--sar").replace('px', ''));
    const sab = Number(window.getComputedStyle(document.documentElement).getPropertyValue("--sab").replace('px', ''));
    const sal = Number(window.getComputedStyle(document.documentElement).getPropertyValue("--sal").replace('px', ''));

    const calcSat = (value) => {
        return value + sat;
    }

    const calcSar = (value) => {
        return value + sar;
    }

    const calcSab = (value) => {
        return value + sab;
    }

    const calcSal= (value) => {
        return value + sal
    }

    return {
        calcSat,
        calcSar,
        calcSab,
        calcSal,
        sat,
        sar,
        sab,
        sal
    }
}

export default useSafeArea;
