import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from '../../components/DatePicker';

import 'format-unicorn';

import { FormControl, Box, TextField } from '@material-ui/core';
import { FormControlLabel, FormLabel, Checkbox } from '@material-ui/core';
import { List, ListItem, ListSubheader } from '@material-ui/core';

import useTranslate from '../../hooks/useTranslate';
import moment from 'moment';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';


import CheckIcon from '@material-ui/icons/Check';
import MoreLayout from '../../layouts/private/MoreLayout';
import useApi from '../../hooks/useApi';
import { useRecoilValue } from 'recoil';
import { userState } from '../../data/user';

function UserReports(props) {
    const t = useTranslate('user')

    const { email } = useRecoilValue(userState)
    const api = useApi()

    const [reportStartDate, setReportStartDate] = useState(moment().subtract(6, 'months').startOf('day').unix());
    const [reportEndDate, setReportEndDate] = useState(moment().endOf('day').unix());

    const [reportEmail, setReportEmail] = useState('');
    const [reportAccept, setReportAccept] = useState(false);
    const [reportDelay, setReportDelay] = useState(5);
    const [reportSend, setReportSend] = useState(false);

    const handleCancel = useCallback(() => {
        setReportSend(false);
        setReportAccept(!reportAccept);
        setTimeout(() => {
            setReportDelay(5);
        }, 200)
    }, [reportAccept])

    const handleSend = useCallback(() => {
        api.userReportCreate({
            begin_at: reportStartDate,
            end_at: reportEndDate,
            email: reportEmail
        }).then(() => {
            handleCancel()
        })
    }, [api, reportStartDate, reportEndDate, reportEmail, handleCancel])

    useEffect(() => {
        if (!reportSend) {
            return;
        }

        const timeout = setTimeout(() => {
            if (reportDelay >= 1) {
                setReportDelay(reportDelay - 1)
            } else {
                handleSend()
            }
        }, 1000)

        return () => clearTimeout(timeout)
    }, [reportSend, reportDelay, handleSend])

    return (
        <MoreLayout>
            <List>
                <ListSubheader disableSticky>{t('reportStartDate')}</ListSubheader>
                <ListItem>
                    <DatePicker momentFormat={date => { return date.startOf('day') }} value={reportStartDate} onChange={setReportStartDate} />
                </ListItem>
                <ListSubheader disableSticky>{t('reportEndDate')}</ListSubheader>
                <ListItem>
                    <DatePicker momentFormat={date => { return date.endOf('day') }} value={reportEndDate} onChange={setReportEndDate} />
                </ListItem>
                <ListSubheader disableSticky>{t('reportRecipient')}</ListSubheader>
                <ListItem style={{ marginTop: -20 }}>{t('reportRecipientText').formatUnicorn({ email })}</ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <TextField
                            type="email"
                            label={t('reportEnterValidEmail')}
                            InputLabelProps={{ shrink: true }}
                            value={reportEmail} onChange={e => setReportEmail(e.target.value)} />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <FormControl style={{ padding: '20px 0px 10px', textAlign: 'center' }} fullWidth>
                        <FormLabel style={{ color: "#757575" }}>{t('reportNotice')}</FormLabel>
                        <FormControlLabel
                            style={{ margin: '0px auto', paddingTop: 10 }}
                            control={<Checkbox checked={reportAccept} onChange={() => setReportAccept(!reportAccept)} />}
                            label={t('reportNoticeAccept')}
                        />
                    </FormControl>
                </ListItem>
            </List>
            {reportAccept &&
                <Box textAlign="center">
                        <Button variant='contained' size='large' style={{ width: '70%', borderRadius: 25, marginBottom: 30 }} color='primary' onClick={() => setReportSend(!reportSend)}>{t('reportSendSubmit')} <CheckIcon style={{ marginLeft: 5 }} /></Button>
                </Box>
            }
            <Dialog
                open={reportSend}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('reportSendingHeader')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('reportSendingText').formatUnicorn({ email: [email, reportEmail].filter(x => x).join(', '), delay: reportDelay })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ borderRadius: 25 }} onClick={handleCancel} color="secondary">{t('reportSendingCancel')}</Button>
                </DialogActions>
            </Dialog>
        </MoreLayout>
    )
}

export default UserReports;
