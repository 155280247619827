import React, { Suspense } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Container, useTheme } from '@material-ui/core';

// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../../hooks/useTranslate';
import ProphylacticIndexList from '../../components/ProphylacticIndexList';
import { useNavigate } from 'react-router';
import { useRefreshProphylacticList } from '../../data/prophylactic';
import PullRefresh from '../../components/PullRefresh';
import LoadingBox from '../../components/LoadingBox';
import useConsole from '../../hooks/useConsole';
import AlertBox from '../../components/AlertBox';
import useSafeArea from '../../hooks/useSafeArea';

function ProphylacticIndex() {
    const t = useTranslate('prophylactic')
    const navigate = useNavigate()
    const refreshProphylacticList = useRefreshProphylacticList()
    const theme = useTheme()
    const console = useConsole()

    const { calcSab } = useSafeArea()

    console.debug('route: ProphylacticIndex')

    const refreshData = async () => {
        refreshProphylacticList();
    }

    return (
        <>
            <Suspense fallback={<LoadingBox height='100vh' />}>
                <PullRefresh className="prophylacticIndex" onRefresh={refreshData}>
                    <AlertBox />
                    <ProphylacticIndexList />
                </PullRefresh>
            </Suspense>
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button id="addProphylactic" onClick={() => navigate('/prophylactics/create')} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('indexRegisterProphylactic')}</span></Button>
            </Container>
            <div style={{ height: 76 }}></div>
        </>
    );
}

export default ProphylacticIndex;
