import React, { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { Button, MenuItem, Menu } from '@material-ui/core';

import { appLanguagesState, appLanguageState } from '../data/app';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function LanguageSwitch() {
    const [appLanguage, setAppLanguage] = useRecoilState(appLanguageState)
    const appLanguages = useRecoilValue(appLanguagesState)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        setAppLanguage(value)
        setAnchorEl(null)
    };

    // ...
    return (
        <>
            <Button onClick={handleMenu}>
                {appLanguage} <KeyboardArrowDownIcon />
            </Button>
            <Menu keepMounted anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} getContentAnchorEl={null} open={Boolean(anchorEl)} anchorEl={anchorEl} PaperProps={{ style: { maxHeight: 400 } }} onClose={handleClose}>
                {Object.entries(appLanguages).map((language, index) => (
                    <MenuItem key={index} selected={appLanguage === language[0]} onClick={() => handleSelect(language[0])}>
                        {language[1]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default LanguageSwitch;
