import React from 'react';
import useTranslate from '../hooks/useTranslate';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { ReactComponent as AttackIcon } from '../icons/attacks.svg';
import { ReactComponent as ProphylacticIcon } from '../icons/prophylactic.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import useSafeArea from '../hooks/useSafeArea';

export default function BottomNavigationMain() {
    const t = useTranslate('layout');

    const location = useLocation();
    const navigate = useNavigate();
    const { sab } = useSafeArea()
    
    const handleNav = (e, value) => {
        if (location.pathname.startsWith('/attacks/') && value.startsWith('/attacks')) {
            navigate('/attacks')
        } else if (location.pathname.startsWith('/prophylactics/') && value.startsWith('/prophylactics')) {
            navigate('/prophylactics')
        } else if (location.pathname.startsWith('/user/') && value.startsWith('/user')) {
            navigate('/more')
        } else {
            navigate(value)
        }
    }

    const attackValue = location.pathname.startsWith('/attacks/') ? location.pathname : '/attacks'
    const prophylacticValue = location.pathname.startsWith('/prophylactics/') ? location.pathname : '/prophylactics'
    const moreValue = location.pathname.startsWith('/user/') ? location.pathname : '/more'

    return (
        <BottomNavigation id="bottomNav" value={location.pathname} onChange={handleNav} style={{ position: 'fixed', bottom: 0, width: '100%', height: 75, borderTop: '1px solid #ECECEE', paddingBottom: sab }} showLabels>
            {/* <BottomNavigationAction style={{ borderTop: 'solid 3px' }} value="/" label={t('indexMenuTabHome')} icon={<HomeIcon style={{ height: 26, marginBottom: 5 }} />} /> */}
            <BottomNavigationAction style={{ borderTop: 'solid 3px' }} value={attackValue} label={t('indexMenuTabAttack')} icon={<AttackIcon style={{ height: 26, marginBottom: 5 }} />} />
            <BottomNavigationAction style={{ borderTop: 'solid 3px' }} value={prophylacticValue} label={t('indexMenuItemProphylactics')} icon={<ProphylacticIcon style={{ height: 26, marginBottom: 5 }} />} />
            <BottomNavigationAction style={{ borderTop: 'solid 3px' }} value={moreValue} label={t('indexMenuTabMore')} icon={<MoreIcon style={{ height: 10, marginBottom: 10, marginTop: 10 }} />} />
        </BottomNavigation>
    );
}
