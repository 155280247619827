/**
 * 
 * @param {*} param0 
 * @returns 
 */
function fetchJSON({ token, language }) {
    /**
     * 
     * @param {*} url 
     * @returns 
     */
    function getHeaders() {
        if (!!token) {
            return {
                'Authorization': `Bearer ${token}`, 
                'Accept-Language': `${language ?? 'en'}`
            };
        }

        return {
            'Accept-Language': `${language ?? 'en'}`
        }
    }

    /**
     * 
     * @param {*} method 
     * @returns 
     */
    function request(method) {
        return async (url, body) => {
            const requestOptions = {
                method,
                headers: getHeaders()
            };

            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            const response = await fetch(url, requestOptions);
            return await handleResponse(response);
        }
    }

    /**
     * 
     * @param {*} response 
     * @returns 
     */
    function handleResponse(response) {
        return response.text().then(text => {

            if (response.status < 200 || response.status >= 300) {
                localStorage.removeItem('authToken');
                return
            }

            return text && JSON.parse(text)
        });
    } 

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    }
}

export default fetchJSON;
