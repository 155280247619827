import React from 'react';

// import { useTranslate } from '../helpers/i18n';
import useTranslate from '../hooks/useTranslate';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useConsole from '../hooks/useConsole';

function PullRefresh({ children, onRefresh, className, style }) {
    const t = useTranslate('layout')
    const console = useConsole()

    console.debug('route: PullRefresh')

    const refreshData = async () => {
        if (typeof onRefresh === 'function') {
            onRefresh();
        }
    }

    return (
        <PullToRefresh className={className} style={style} pullingContent={t('indexPullToRefresh')} onRefresh={refreshData}>
            {children}
        </PullToRefresh>
    );
}

export default PullRefresh;
