import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';

import { IconButton, CardContent, useTheme } from '@material-ui/core';
import HealingIcon from '@material-ui/icons/Healing';
import DeleteIcon from '@material-ui/icons/Delete';
import { appOptionsState } from '../data/app';
import useTranslate from '../hooks/useTranslate';
import ConfirmDialog from './ConfirmDialog';
import useApi from '../hooks/useApi';

function TreatmentCardContent({ treatment }) {

    const t = useTranslate('treatment')
    const theme = useTheme()
    const options = useRecoilValue(appOptionsState)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { treatmentDelete } = useApi()

    const handleDeleteClose = () => {
        setConfirmDelete(!confirmDelete);
    }

    const handleDeleteConfirm = async () => {
        setConfirmDelete(!confirmDelete);
        await treatmentDelete(treatment)
    }

    return (
        <CardContent>
            <IconButton onClick={e => { e.stopPropagation(); setConfirmDelete(!confirmDelete) }} style={{ float: 'right', padding: 5 }}>
                <DeleteIcon />
            </IconButton>
            <div>
                <div style={{ backgroundColor: theme.palette.primary.main, padding: '2px 2px 0px 2px', borderRadius: 5, color: theme.palette.primary.contrastText, float: 'left' }}>
                    <HealingIcon />
                </div>
                <h3 style={{ paddingLeft: 10, paddingTop: 3, margin: 0, float: 'left' }}>{moment.unix(treatment.treated_at).format('LLL')}</h3>
            </div>
            <div style={{ clear: 'both' }}></div>
            <h4 style={{ marginBottom: 0 }}>{options.treatment.therapies[treatment.therapy_id].name}</h4>
            <ul style={{ margin: 0, color: theme.palette.text.secondary, paddingLeft: 25, lineHeight: 1.5 }}>
                {treatment.units.map((unit, index) => {
                    return <li key={index}>{unit.amount} x {options.treatment.therapies[treatment.therapy_id].units[unit.unit_id]}</li>
                })}
            </ul>
            {confirmDelete && 
                <ConfirmDialog open={true} close={handleDeleteClose} confirm={handleDeleteConfirm} title={t('deleteDialogHeadline')} text={t('deleteDialogText')} />
            }
        </CardContent>
    )
}

export default TreatmentCardContent;
