import moment from 'moment';

export default function mktime(language, date, time) {
    moment.locale(language)

    const format    = moment.localeData().longDateFormat('L') + moment.localeData().longDateFormat('LT');
    const timestamp = moment.unix(date).format('L') + moment.unix(time).format('LT');
    const _moment   = moment(timestamp, format);

    return _moment.unix();
};
