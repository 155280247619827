import { useRecoilValue, useSetRecoilState } from "recoil";
import useApiFetch from "./useApiFetch";
import useConsole from "./useConsole";
import { apiUrlState } from "../data/app";
import { useRefreshProphylacticList } from "../data/prophylactic";
import { useRefreshAttacksVersion } from "../data/attack";
import { appState, apiTokenState, appLanguageState } from "../data/app";
import { userLastLoginState } from "../data/user";

const filterItems = (items) => {
    return items.map(item => {
        return item.note ? { id: Number(item.id), note: item.note} : { id: Number(item.id) }
    });
}

/**
 * useApi
 * This hook handles create, update and delete methods. When data are fetched, they
 * are handled via recoil selectors.
 * 
 * @see file src/data/selectors.js
 */
function useApi() {
    const apiFetch = useApiFetch()
    const apiUrl = useRecoilValue(apiUrlState)
    const app = useRecoilValue(appState)
    const console = useConsole()

    const setApiToken = useSetRecoilState(apiTokenState)
    const setAppLanguage = useSetRecoilState(appLanguageState)
    const setUserLastLogin = useSetRecoilState(userLastLoginState)
    const { version } = useRecoilValue(appState)

    const refreshProphylacticList = useRefreshProphylacticList()
    const refreshAttacksVersion = useRefreshAttacksVersion()

    const appNotifications = async () => {
        console.debug('api: appNotifications')

        return await apiFetch.get(`${apiUrl}/api/?r=notification/index`)
    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    const userCreate = async (data) => {
        console.debug('api: userCreate')


        return await apiFetch.post(`${apiUrl}/auth/?r=user/create&version=${version}`, data)
    }

    /**
     * 
     * @param {*} email 
     * @returns 
     */
    const userCheckMail = async (mail) => {
        console.debug('api: userCheckMail')

        return await apiFetch.get(`${apiUrl}/auth/?r=user/check-mail&mail=${mail}`)
    }

    /**
     * 
     * @param {*} email 
     * @returns 
     */
    const userSendVerificationMail = async (email) => {
        console.debug('api: userSendVerificationMail')

        return await apiFetch.post(`${apiUrl}/auth/?r=user/send-verification`, {
            email
        })
    }

    /**
     * 
     * @param {*} email 
     * @param {*} token 
     * @returns 
     */
    const userVerifyAccount = async (email, token) => {
        console.debug('api: userVerifyAccount')

        return await apiFetch.post(`${apiUrl}/auth/?r=user/verify-account`, {
            email,
            token
        })
    }

    /**
     * 
     * @param {*} phone_country 
     * @param {*} phone 
     * @returns 
     */
    const userRequestVerifyPhoneToken = async (phone_country, phone) => {
        return await apiFetch.post(`${apiUrl}/auth/?r=user/request-verify-phone-token&phone_country=${phone_country}&phone=${phone}`)
    }

    const userRequestVerifyEmailToken = async (email) => {
        return await apiFetch.post(`${apiUrl}/auth/?r=user/request-verify-email-token&email=${encodeURIComponent(email)}`)
    }

    /**
     * 
     * @param {*} uniqid 
     * @param {*} token 
     * @returns 
     */
    const userVerifyPhoneToken = async (uniqid, token) => {
        return await apiFetch.post(`${apiUrl}/auth/?r=user/verify-phone-token&uniqid=${uniqid}&token=${token}`)
    }

    const userVerifyEmailToken = async (uniqid, token) => {
        return await apiFetch.post(`${apiUrl}/auth/?r=user/verify-email-token&uniqid=${uniqid}&token=${token}`)
    }

    /**
     * 
     * @param {*} username 
     * @param {*} password 
     * @returns 
     */
    const userLogin = async (username, password) => {
        console.debug('api: userLogin')

        const response = await apiFetch.post(`${apiUrl}/auth/?r=user/login`, {
            username,
            password,
            version: app.version
        })

        if (response.length !== 1) {
            return null
        }

        setApiToken(response[0].token)
        localStorage.setItem('token', response[0].token);
        setAppLanguage(response[0].language)
        localStorage.setItem('language', response[0].language);
        setUserLastLogin(response[0].last_login)
        localStorage.setItem('last_login', response[0].last_login);
        return response[0]
    }

    /**
     * 
     * @param {*} email 
     * @returns 
     */
    const userForgotPassword = async (email) => {
        console.debug('api: userForgotPassword')

        return await apiFetch.post(`${apiUrl}/auth/?r=user/forgot-password`, {
            email
        })
    }

    /**
     * 
     * @param {*} email 
     * @param {*} token 
     * @param {*} password 
     * @returns 
     */
    const userResetPassword = async (email, token, password) => {
        console.debug('api: userResetPassword')

        return await apiFetch.post(`${apiUrl}/auth/?r=user/reset-password`, {
            email,
            token,
            password
        })
    }

    /**
     * 
     * @param {*} data 
     */
    const userReportCreate = async (data) => {
        console.debug('api: userReportCreate')

        await apiFetch.post(`${apiUrl}/api/?r=report/create`, {
            begin_at: data.begin_at,
            end_at: data.end_at,
            email: data.email,
        })
    }

    /**
     * 
     * @param {*} data 
     */
    const userUpdate = async (data) => {
        console.debug('api: userUpdate')

        await apiFetch.post(`${apiUrl}/auth/?r=user/update`, data)
    }

    /**
     * 
     * @param {*} data 
     */
    const userDelete = async (data) => {
        console.debug('api: userDelete')

        await apiFetch.post(`${apiUrl}/auth/?r=user/delete`, data)
    }

    /**
     * 
     * @param {*} data 
     */
    const userTestSMS = async () => {
        console.debug('api: userTestSMS')

        return await apiFetch.get(`${apiUrl}/api/?r=app/test-sms`)
    }

    const attackLoadMore = async (offset) => {
        console.debug('api: attackLoadMore')

        return await apiFetch.get(`${apiUrl}/api/?r=attack/index&limit=2&offset=${offset}`)
    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    const attackCreate = async (data) => {
        console.debug('api: attackCreate')

        const newAttack = await apiFetch.post(`${apiUrl}/api/?r=attack/create`, {
            occurred_at:               data.occurred_at,
            areas:                     filterItems(data.areas),
            symptoms:                  filterItems(data.symptoms),
            // affect:                    data.affect,
            // affect_timeloss:           data.affect_timeloss,
            // caregiver_affect:          data.caregiver_affect,
            // caregiver_affect_timeloss: data.caregiver_affect_timeloss,
            triggers:                  filterItems(data.triggers),
        })
        
        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshAttacksVersion()

        return newAttack
    }

    /**
     * 
     * @param {*} attack 
     * @param {*} data 
     */
    const attackUpdate = async (attack, data) => {
        console.debug('api: attackUpdate')

        const updatedAttack = await apiFetch.post(`${apiUrl}/api/?r=attack/update&id=${attack.id}`, {
            occurred_at:               data.occurred_at || attack.occurred_at,
            improved_at:               data.improved_at !== undefined ? data.improved_at : attack.improved_at,
            resolved_at:               data.resolved_at !== undefined ? data.resolved_at : attack.resolved_at,
            areas:                     filterItems(data.areas    ? data.areas    : attack.areas),
            symptoms:                  filterItems(data.symptoms ? data.symptoms : attack.symptoms),
            triggers:                  filterItems(data.triggers ? data.triggers : attack.triggers),
            affect:                    data.affect !== undefined ? data.affect : attack.affect,
            affect_timeloss_work:      data.affect_timeloss_work !== undefined ? data.affect_timeloss_work  : attack.affect_timeloss_work,
            affect_timeloss_study:     data.affect_timeloss_study !== undefined ? data.affect_timeloss_study : attack.affect_timeloss_study,
            affect_timeloss_sleep:     data.affect_timeloss_sleep !== undefined ? data.affect_timeloss_sleep : attack.affect_timeloss_sleep,
            affect_timeloss_other:     data.affect_timeloss_other !== undefined ? data.affect_timeloss_other : attack.affect_timeloss_other,
            caregiver_affect:                    data.caregiver_affect !== undefined ? data.caregiver_affect : attack.caregiver_affect,
            caregiver_affect_timeloss_work:      data.caregiver_affect_timeloss_work !== undefined  ? data.caregiver_affect_timeloss_work  : attack.caregiver_affect_timeloss_work,
            caregiver_affect_timeloss_study:     data.caregiver_affect_timeloss_study !== undefined ? data.caregiver_affect_timeloss_study : attack.caregiver_affect_timeloss_study,
            caregiver_affect_timeloss_sleep:     data.caregiver_affect_timeloss_sleep !== undefined ? data.caregiver_affect_timeloss_sleep : attack.caregiver_affect_timeloss_sleep,
            caregiver_affect_timeloss_other:     data.caregiver_affect_timeloss_other !== undefined ? data.caregiver_affect_timeloss_other : attack.caregiver_affect_timeloss_other,
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshAttacksVersion()

        return updatedAttack
    }

    /**
     * 
     * @param {*} attack 
     */
    const attackDelete = async (attack) => {
        console.debug('api: attackDelete')

        await apiFetch.delete(`${apiUrl}/api/?r=attack/delete&id=${attack.id}`)

        refreshAttacksVersion()

        // const newValue = [...attacks]
        // for (let index = 0; index < newValue.length; index++) {
        //     if (newValue[index].id === attack.id) {
        //         newValue.splice(index, 1)
        //         break
        //     }
        // }

        // setAttacks(newValue)
    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    const attackCreateTreatment = async (data) => {
        console.debug('api: attackCreateTreatment')

        const treatment = await apiFetch.post(`${apiUrl}/api/?r=attack/create-treatment&expand=therapy`, {
            attack_id: Number(data.attack_id),
            treated_at: data.treated_at,
            location_id: Number(data.location_id),
            therapy: data.therapy,
        })

        await treatmentUpdate(treatment, {
            effect: data.effect ?? 0,
            side_effects: data.side_effects ?? [],
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshAttacksVersion()
    }

    /**
     * @todo handle treatment "effect" and "side_effects"
     * @param {*} treatment 
     * @param {*} data 
     * @returns 
     */
    const attackUpdateTreatment = async (treatment, data) => {
        console.debug('api: attackUpdateTreatment')

        await apiFetch.post(`${apiUrl}/api/?r=treatment/update&id=${treatment.id}`, data)

        await treatmentUpdate(treatment, {
            effect: data.effect ?? 0,
            side_effects: data.side_effects ?? [],
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshAttacksVersion()
    }

    /**
     * 
     * @param {*} data 
     * @returns 
     */
    const prophylacticCreate = async (data) => {
        console.debug('api: prophylacticCreate')

        const prophylactic = await apiFetch.post(`${apiUrl}/api/?r=prophylactic/create`, {
            begin_at:         data.begin_at,
            end_at:           data.end_at,
            remind_at_date:   (data.notification && data.notification.atDate) ? Number(data.notification.atDate) : 0,
            remind_at_time:   (data.notification && data.notification.atTime) ? Number(data.notification.atTime) : 0,
            remind_repeat:    (data.notification && data.notification.repeat) ? data.notification.repeat : 'never',
            remind_timestamp: (data.notification && data.notification.timestamp) ? Number(data.notification.timestamp) : 0,
            remind_custom:    (data.notification && data.notification.custom) ? JSON.stringify(data.notification.custom) : null,
            therapy:          data.therapy,
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()

        return prophylactic
    }

    /**
     * 
     * @param {*} prophylactic 
     * @param {*} data 
     */
    const prophylacticUpdate = async (prophylactic, data) => {
        console.debug('api: prophylacticUpdate')

        await apiFetch.post(`${apiUrl}/api/?r=prophylactic/update&id=${prophylactic.id}`, {
            begin_at:         data.begin_at,
            end_at:           data.end_at,
            remind_at_date:   (data.notification && data.notification.atDate) ? Number(data.notification.atDate) : 0,
            remind_at_time:   (data.notification && data.notification.atTime) ? Number(data.notification.atTime) : 0,
            remind_repeat:    (data.notification && data.notification.repeat) ? data.notification.repeat : 'never',
            remind_timestamp: (data.notification && data.notification.timestamp) ? Number(data.notification.timestamp) : 0,
            remind_custom:    (data.notification && data.notification.custom) ? JSON.stringify(data.notification.custom) : null,
            therapy:          data.therapy
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
    }

    /**
     * 
     * @param {*} treatment 
     */
    const prophylacticDelete = async (treatment) => {
        console.debug('api: prophylacticDelete')

        await apiFetch.post(`${apiUrl}/api/?r=prophylactic/delete&id=${treatment.id}`)

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
    }

    /**
     * @todo: handle treatment "effect" and "side_effects"
     * @param {*} data 
     * @returns 
     */
    const prophylacticCreateTreatment = async (data) => {
        console.debug('api: createProphylacticTreatment')

        const treatment = await apiFetch.post(`${apiUrl}/api/?r=prophylactic/create-treatment`, {
            prophylactic_id: Number(data.prophylactic_id),
            therapy: data.therapy,
            treated_at: data.treated_at,
            location_id: Number(data.location_id)
        })

        await treatmentUpdate(treatment, {
            effect: data.effect ?? 0,
            side_effects: data.side_effects ?? [],
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
    }

    /**
     * 
     * @param {*} treatment 
     * @param {*} data 
     * @returns 
     */
    const prophylacticUpdateTreatment = async (treatment, data) => {
        console.debug('api: updateProphylacticTreatment')

        await apiFetch.post(`${apiUrl}/api/?r=treatment/prophylactic-update&id=${treatment.id}`, {
            therapy_id: data.therapy_id,
            note: data.note,
            units: data.therapy?.units ?? [],
            treated_at: data.treated_at,
        })

        await treatmentUpdate(treatment, {
            effect: data.effect ?? 0,
            side_effects: data.side_effects ?? [],
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
    }

    /**
     * 
     * @param {*} treatment 
     * @param {*} data 
     * @returns 
     */
    const treatmentUpdate = async (treatment, data) => {
        console.debug('api: treatmentUpdate')

        await apiFetch.post(`${apiUrl}/api/?r=treatment/update-impact&id=${treatment.id}`, {
            effect: Number(data.effect) ?? 0,
            // evaluated_at: data.evaluated_at ?? 0,
            side_effects: data.side_effects ?? []
        })

        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
        refreshAttacksVersion()
    }

    const treatmentDelete = async (treatment) => {
        console.debug('api: treatmentDelete')

        await apiFetch.delete(`${apiUrl}/api/?r=treatment/delete&id=${treatment.id}`)
        
        /**
         * this can be optimized by updating the current state,
         * but for now we fetch a new state 
         */
        refreshProphylacticList()
        refreshAttacksVersion()
    }

    /**
     * 
     */
    return {
        appNotifications,
        userCreate,
        userCheckMail,
        userSendVerificationMail,
        userVerifyAccount,
        userRequestVerifyPhoneToken,
        userRequestVerifyEmailToken,
        userVerifyPhoneToken,
        userVerifyEmailToken,
        userLogin,
        userForgotPassword,
        userResetPassword,
        userReportCreate,
        userUpdate,
        userDelete,
        userTestSMS,
        attackCreate,
        attackUpdate,
        attackDelete,
        attackCreateTreatment,
        attackUpdateTreatment,
        attackLoadMore,
        prophylacticCreate,
        prophylacticUpdate,
        prophylacticDelete,
        prophylacticCreateTreatment,
        prophylacticUpdateTreatment,
        treatmentDelete,

        // old naming
        createAttackTreatment: attackCreateTreatment,
        updateAttackTreatment: attackUpdateTreatment,
        createProphylacticTreatment: prophylacticCreateTreatment,
        updateProphylacticTreatment: prophylacticUpdateTreatment
    }
}

export default useApi;
