import React from 'react';
import { Box, Select, FormHelperText, MenuItem } from '@material-ui/core';

function InputFieldSelect({ name, type, variant, options, label, value, hint, autoFocus, onChange }) {
    const handleOnChange = (value) => {
        if (typeof onChange === 'function') {
            onChange(value)
        }
    }

    return (
        <Box style={{ width: '100%' }}>
            <label htmlFor={name} style={{ textAlign: 'left', display: 'block' }}>{label}</label>
            {hint &&
                <FormHelperText>{hint}</FormHelperText>
            }
            <Select
                type={type ?? 'text'}
                variant={variant ?? 'outlined'}
                id={name}
                name={name}
                fullWidth
                inputProps={{ style: { backgroundColor: '#F8F8FA' } }}
                style={{ marginTop: 8, marginBottom: 20 }}
                value={value}
                autoFocus={autoFocus}
                onChange={e => handleOnChange(e.target.value)}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.key}>{option.value}</MenuItem>
                ))}
            </Select>
        </Box>
    )
}

export default InputFieldSelect;
