import React from 'react';
import { Button, Card, CardContent, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import { ReactComponent as ProphylacticIcon } from '../icons/prophylactic.svg';
import HealingIcon from '@material-ui/icons/Healing';
import NotificationsIcon from '@material-ui/icons/Notifications';


import { useRecoilValue } from 'recoil';
import { prophylacticsState } from '../data/prophylactic';
import { useNavigate } from 'react-router';
import useTranslate from '../hooks/useTranslate';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { appOptionsState } from '../data/app';
import useConsole from '../hooks/useConsole';

function ProphylacticIndexList() {
    const navigate = useNavigate()
    const theme = useTheme()
    const console = useConsole()

    const t = useTranslate('prophylactic')

    const prophylactics = useRecoilValue(prophylacticsState)
    const options = useRecoilValue(appOptionsState)

    console.debug('route: ProphylacticIndexList');

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getUnitList = (therapy_id, units) => {
        let str = []
        if (units?.length === 0) {
            return
        }

        for (let index = 0; index < units.length; index++) {
            const unit = units[index];
            str.push(unit.amount +' x '+ (unit.unit_id > 0 ? options.treatment.therapies[therapy_id].units[unit.unit_id] : (unit.note ?? t('indexProphylacticTreatmentUnitEmpty'))))
        }

        return str.join(' | ')
    }

    return (
        <>
            {prophylactics.map((prophylactic, index) => {
                return (
                    <Card onClick={() => navigate(`/prophylactics/${prophylactic.id}`)} style={{ margin: 20, borderRadius: 10 }} raised key={index}>
                        <CardContent>
                        <IconButton onClick={e => { e.stopPropagation(); navigate(`/prophylactics/${prophylactic.id}/update`, { state: { activeStep: 2 } })} } style={{ float: 'right', padding: 5, backgroundColor: (prophylactic.remind_at_date > 0 ? theme.palette.success.dark : theme.palette.common.white), color: (prophylactic.remind_at_date > 0 ? theme.palette.common.white : '') }}>
                            <NotificationsIcon />
                        </IconButton>
                            <div>
                                <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 5, color: theme.palette.primary.contrastText, float: 'left', padding: '2px 4px 2px 5px' }}>
                                    <ProphylacticIcon style={{ height: 24, marginTop: 2 }} />
                                </div>
                                <h3 style={{ paddingLeft: 10, paddingTop: 5, margin: 0, float: 'left' }}>{options.treatment.therapies[prophylactic.therapy_id].name}</h3>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                            <Grid  style={{ color: (prophylactic.status === 'active' ? theme.palette.success.dark : theme.palette.text.secondary ), fontWeight: 600, marginTop: 10, marginBottom: 5 }} alignContent="center" alignItems="center" container>
                                <Grid item xs={1}>{prophylactic.status === 'active' ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}</Grid>
                                <Grid item style={{ paddingBottom: 2 }} xs>{t('indexProphylactic'+ capitalize(prophylactic.status))}</Grid>
                            </Grid>
                            <Typography><b>{t('indexProphylacticStart')}:</b> {moment.unix(prophylactic.begin_at).format('LL')}</Typography>
                            <Typography><b>{t('viewTreatmentUnits')}:</b> {getUnitList(prophylactic.therapy_id, prophylactic.units)}</Typography>
                            <Typography><b>{t('viewLastTreatment')}:</b> {prophylactic.treatments.length === 0 ? '?' : moment.unix(prophylactic.treatments[0].treated_at).format('ll')}</Typography>
                            <Button onClick={e => { e.stopPropagation(); navigate(`/prophylactics/${prophylactic.id}/treatments/create`)} } style={{ borderWidth: 2, borderRadius: 20, marginTop: 20, textTransform: 'none', borderColor: '#575757', color: '#575757' }} variant="outlined"><HealingIcon />&nbsp;&nbsp;{t('viewAddTreatment')}</Button>
                        </CardContent>
                    </Card>
                )
            })}
        </>
    );
}

export default ProphylacticIndexList;
