import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import useTranslate from '../hooks/useTranslate';
import moment from 'moment';

import { FormControl, FormHelperText } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { userState } from '../data/user';


export default function DatePicker({ momentFormat, onChange, value, minDate, maxDate }) {
    const t = useTranslate('component');
    const { language_datetime } = useRecoilValue(userState);

    const handleChange = (date) => {
        if (momentFormat !== undefined) {
            date = momentFormat(date);
        }

        if (date !== null) {
            onChange(date.unix())
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateMomentUtils} locale={language_datetime}>
            <FormControl fullWidth>
                <KeyboardDatePicker
                    // variant="static"
                    id="date-picker-dialog"
                    label={t('datePickerLabel')}
                    // variant="inline"
                    format={moment.localeData().longDateFormat('L')}
                    value={value ? moment.unix(value) : null}
                    InputLabelProps={{ shrink: true }}
                    minDate={(minDate !== undefined && minDate > 0) ? moment.unix(minDate) : new Date('2000-01-01')}
                    maxDate={(maxDate !== undefined && maxDate > 0) ? moment.unix(maxDate) : new Date()}
                    onChange={handleChange}
                    cancelLabel={t('datePickerLabelCancel')}
                    okLabel={t('datePickerLabelOK')}
                />
                <FormHelperText>{t('datePickerHint')}</FormHelperText>
            </FormControl>
        </MuiPickersUtilsProvider>
    )
}
