import React from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';

import { attackState, attackTreatmentState } from '../../data/attack';
import AttackTreatmentsCreate from './AttackTreatmentsCreate';

function AttackUpdate() {
    const { attack_id, treatment_id } = useParams();
    const attack = useRecoilValue(attackState(attack_id))
    const treatment = useRecoilValue(attackTreatmentState({ attack_id, treatment_id }))

    if (attack === undefined) {
        return null;
    }

    return (
        <AttackTreatmentsCreate attack={attack} treatment={treatment} />
    )
}

export default AttackUpdate;
