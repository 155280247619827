import React, { Suspense } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Container, useTheme } from '@material-ui/core';

import useTranslate from '../../hooks/useTranslate';
import AttackIndexList from '../../components/AttackIndexList';
import { useNavigate } from 'react-router';
import { useRefreshAttacksVersion } from '../../data/attack';
import PullRefresh from '../../components/PullRefresh';
import LoadingBox from '../../components/LoadingBox';
import useConsole from '../../hooks/useConsole';
import AlertBox from '../../components/AlertBox';
import useSafeArea from '../../hooks/useSafeArea';

function AttackIndex() {
    const t = useTranslate('attack')
    const navigate = useNavigate()
    const refreshAttacksVersion = useRefreshAttacksVersion()
    const theme = useTheme()
    const console = useConsole()
    const { calcSab } = useSafeArea()

    console.debug('route: AttackIndex')

    const refreshData = async () => {
        refreshAttacksVersion()
    }

    return (
        <>
            <Suspense fallback={<LoadingBox height='100vh' />}>
                <PullRefresh style={{ height: '100vh' }} onRefresh={refreshData} className="attackIndex">
                    <AlertBox />
                    <AttackIndexList />
                </PullRefresh>
            </Suspense>
            <Container className='xyz' style={{ textAlign: 'center', position: 'fixed', paddingBottom: 20, bottom: calcSab(76) }} maxWidth={false}>
                <Button onClick={() => navigate('/attacks/create')} style={{ borderRadius: 50, backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, textTransform: 'none' }}><AddIcon style={{ backgroundColor: theme.palette.primary.light, borderRadius: 50, padding: 10 }} /> <span style={{ paddingLeft: 15, paddingRight: 20, fontSize: '1.2em', fontWeight: 600 }}>{t('indexRegisterAttack')}</span></Button>
            </Container>
            <div style={{ height: 76 }}></div>
        </>
    );
}

export default AttackIndex;
