import { atom, selector, selectorFamily, useSetRecoilState } from "recoil";
import { apiUrlState, apiTokenState, appDataVersionState, appLanguageState, appState } from "./app";
import { findTreatmentInList } from '../helpers/recoil';
import fetchJSON from "../helpers/fetchJSON";

/**
 * 
 */
export const prophylacticsVersionState = atom({
    key: "prophylacticsVersionState",
    default: Date.now(),
});

/**
 * 
 * @returns 
 */
export function useRefreshProphylacticList() {
    const setState = useSetRecoilState(prophylacticsVersionState);
    return () => {
        setState(Date.now());
    };
}

/**
 * 
 */
export const prophylacticsState = atom({
    key: "prophylacticsState",
    default: selector({
        key: 'prophylacticsStateSelector',
        get: async ({ get }) => {
            get(appDataVersionState)
            get(prophylacticsVersionState);
            if (get(appState).state !== 'prod') {
                console.debug('data: prophylacticsStateSelector')
            }

            return await fetchJSON({
                token: get(apiTokenState),
                language: get(appLanguageState)
            }).get(`${get(apiUrlState)}/api/?r=prophylactic/index`)
        },
    })
});

/**
 * 
 */
export const prophylacticState = selectorFamily({
    key: 'prophylacticState',
    get: (prophylacticId) => async ({ get }) => {
        if (prophylacticId === undefined) {
            return null;
        }

        const prophylactics = get(prophylacticsState)
        for (let index = 0; index < prophylactics.length; index++) {
            const prophylactic = prophylactics[index];
            if (prophylactic.id === Number(prophylacticId)) {
                return prophylactic
            }
        }
    },
});

/**
 * 
 */
export const prophylacticTreatmentState = selectorFamily({
    key: 'prophylacticTreatmentState',
    get: ({ prophylactic_id, treatment_id }) => async ({ get }) => {
        const prophylactics = get(prophylacticsState)
        return findTreatmentInList(prophylactics, prophylactic_id, treatment_id)
    },
});
