import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Box } from '@material-ui/core';

import BottomNavigationMain from '../../components/BottomNavigationMain';
import AppBarProphylactic from '../../components/AppBarProphylactic';
import LoadingBox from '../../components/LoadingBox';
import getContentMargins from '../../helpers/getContentMargins';
import useSafeArea from '../../hooks/useSafeArea';

function ProphylacticLayout({ children }) {
    // const [marginTop, marginBottom] = getContentMargins()
    const [marginTop, marginBottom] = getContentMargins()
    // const { calcSat, calcSab } = useSafeArea()
    const { calcSat, calcSab } = useSafeArea()

    return (
        <>
            <AppBarProphylactic />
            <Box className='fullHeight' style={{ backgroundColor: '#F7F7F9', overflow: 'auto' }}>
                <div style={{ height: calcSat(marginTop) }}></div>
                <Suspense fallback={<LoadingBox />}>
                    {children === undefined ? <Outlet /> : children}
                </Suspense>
                <div style={{ height: calcSab(marginBottom) }}></div>
            </Box>
            <BottomNavigationMain />
        </>
    )
}

export default ProphylacticLayout;
