const mapSideEffectsData = (effect, sideEffects, sideEffectNotes) => {
    let sideEffectData = []
    if (Number(effect) > 0) {
        for (let index = 0; index < sideEffects.length; index++) {
            const element = sideEffects[index];

            sideEffectData.push({
                id: element,
                note: sideEffectNotes[element] ? sideEffectNotes[element] : null
            })
        }
    }

    return sideEffectData
}

export default mapSideEffectsData;
