import React from 'react';

import { Grid } from '@material-ui/core';

import DatePicker from './DatePicker';
import TimePicker from './TimePicker';


function DateTimePicker({ value, minDate, maxDate, onChange }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <DatePicker value={value} minDate={minDate} maxDate={maxDate} onChange={onChange} />
            </Grid>
            <Grid item xs={6}>
                <TimePicker value={value} minDate={minDate} maxDate={maxDate} onChange={onChange} />
            </Grid>
        </Grid>
    )
}

export default DateTimePicker;
